import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store, { persistor } from './_redux/store';
import App from './App/App';
import { LicenseInfo } from '@mui/x-license-pro';
import './index.scss'
import { reactPlugin } from './App/AppInsights';
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";


LicenseInfo.setLicenseKey('166f51579c98ec7b97b700c9581cf376Tz01MDgxNyxFPTE2OTQ3NDQyMjkzMjgsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

// setup fake backend
// import { configureFakeBackend } from './_helpers';
// configureFakeBackend();



// const onRedirectCallback = (appState) => {
//     // console.log("Redirect Call back", appState)
//     // If using a Hash Router, you need to use window.history.replaceState to
//     // remove the `code` and `state` query parameters from the callback url.
//     // window.history.replaceState({}, document.title, window.location.pathname);
//     navigate((appState && appState.returnTo) || window.location.pathname);
// };

const rootElement = document.getElementById("app");
const root        = createRoot(rootElement);

root.render(
    // <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <AppInsightsContext.Provider value={reactPlugin}>
                    <App store={store} persistor={persistor} />
                </AppInsightsContext.Provider>
            </BrowserRouter>
        </Provider>
    // </React.StrictMode>
);