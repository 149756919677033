
import { publicUserConstants } from '../_constants/publicuser.constants';

export const publicUserActions = {
    login,
    logout,
    refresh,
    request,
    failure
};

function refresh(user) {
    return { type: publicUserConstants.LOGIN_REFRESH, user }
}

function login(user) {
    return { type: publicUserConstants.LOGIN_SUCCESS, user }
}

function request(user){
    return { type: publicUserConstants.LOGIN_REQUEST, user }
}

function failure(error){
    return { type: publicUserConstants.LOGIN_FAILURE, error }
}

function logout() {
    return { type: publicUserConstants.LOGOUT };
}

