import { toastr } from "react-redux-toastr";
import { io } from 'socket.io-client';
import store from "../_redux/store";


export const API_URL = process.env.NODE_ENV === "development" ? "http://localhost:4000" : "https://middle-layer.azurewebsites.net";


function getAuthToken(){
    const { authentication: { user } } = store.getState();

    if(user && user?.accessToken){
        return user?.accessToken
    }else{
        return null
    }

}

function getAuthTokenPublic(){
    const { publicuser: { user } } = store.getState();

    if(user && user?.accessToken){
        return user?.accessToken
    }else{
        return null
    }

}

export function authHeader() {

    const authToken = getAuthToken();

    if (authToken) {
        return { 
            'Authorization': 'Bearer ' + authToken,
            'Content-Type': 'application/json'
        };
    } else {
        return { 
            'Content-Type': 'application/json'
        };
    }
}

export const socket = io( `${API_URL}/`, {
    reconnectionDelayMax: 10000,
    autoConnect: true,
    auth:(cb) => {
        cb({
          token: getAuthToken()
        });    
    }
})


export const publicSocket = io( `${API_URL}/`, {
    reconnectionDelayMax: 10000,
    autoConnect: true,
    auth:(cb) => {
        cb({
          token: getAuthTokenPublic() || "PUBLIC_TOKEN"
        });    
    }
})


export function handleResponse(response) {
    // console.log("This is the response",response)
    return response.text().then(text => {

        const responseText = text && JSON.parse(text);
        const data = responseText;

        // If the jwt expired, send to logout landing page.
        if(data.message === "jwt expired" && response.status === 401){
            // toastr.warning("Your Session has expired", "Logging you out.")
            // window.location.href = `${window.location.origin}/expired`
        }

        if (!response.ok) {
            const error = (data && data.data.message) || response.statusText;
            throw { status: response.status, data: { message: error }}
        }

        return data;
    });
}
