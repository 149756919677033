import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Box,
    Typography
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Grid from '@mui/material/Unstable_Grid2';
import { useSprings, animated } from '@react-spring/web'
import { useDrag, useGesture } from '@use-gesture/react'
import React, { useEffect } from "react";
import useHover from "../../_helpers/customhooks/custom.hook.onHover";

const Steps = [
    {
        id: 0,
        stepName: "Import Data",
        started: false,
        error: false,
        errorMessage: ""
    },
    {
        id: 1,
        stepName: "Column Mapping",
        started: false,
        error: false,
        errorMessage: ""
    },
    {
        id: 2,
        stepName: "Processing Data",
        started: false,
        error: false,
        errorMessage: ""
    },

]

const MockData = [
    {
        id: 0,
        columnName: "Column A",
        firstRowsData: ["1", "3", "4"]
    },
    {
        id: 1,
        columnName: "Column B",
        firstRowsData: ["1", "3", "4"]
    },
    {
        id: 2,
        columnName: "Column C",
        firstRowsData: ["1", "3", "4"]
    },
    {
        id: 3,
        columnName: "Column D",
        firstRowsData: ["1", "3", "4"]
    },
    {
        id: 4,
        columnName: "Column E",
        firstRowsData: ["1", "3", "4"]
    },
    {
        id: 5,
        columnName: "Column F",
        firstRowsData: ["1", "3", "4"]
    },
]

const MockRequiredColumns = [
    {
        id: 0,
        columnName: "First Name",
        dataType: "STRING"
    },
    {
        id: 1,
        columnName: "Last Name",
        dataType: "STRING"
    },
    {
        id: 2,
        columnName: "Start Date",
        dataType: "DATETIME",
        TimeZone: "UTC"
    },
    {
        id: 3,
        columnName: "Totals",
        dataType: "FLOAT"
    },

]

const CSVHandler = () => {

    const [data, setData]                             = React.useState([...MockData]);
    const [definedDataPoints, setDefinedDataPoints]   = React.useState([...MockRequiredColumns])
    const [showCurrentHeaders, setShowCurrentHeaders] = React.useState({ startIndex: 0, endIndex: 3 });
    const [subset, setSubset]                         = React.useState([]);

    React.useEffect(() => {
        if (subset.length < 1 && data.length > 0) {
            setSubset(data.slice(showCurrentHeaders.startIndex, showCurrentHeaders.endIndex));
        }
    }, [data, subset]);

    const doSomethingWith = (e) => {
        console.log(e)
    }

    const [springs, api] = useSprings(subset.length, () => ({ x: 0, y: 0 }));
    // Set the drag hook and define component movement based on gesture data
    // const bind = useDrag(({ down, movement: [mx, my], args: [index], moving, event, target, currentTarget }) => {
    //                 console.log( event.target.id, target.id)
    //                  api.start((i) => i === index && { x: down ? mx : 0, y: down ? my : 0, immediate: down })
                     

    //             });
    // Set the drag hook and define component movement based on gesture data
    const [draggingElement, setDraggingElement] = React.useState(null);

    const bind = useGesture({
                    onDrag: (state) => {
                        const { down, movement: [mx, my], args: [index], moving, event, target, currentTarget } = state;
                        api.start((i) => i === index && { x: down ? mx : 0, y: down ? my : 0, immediate: down })
                        if(target.id){
                            const findDraggingElement = subset.find((el) => parseInt(el.id) === parseInt(target.id));
                            // console.log("You are dragging", findDraggingElement);
                            setDraggingElement(findDraggingElement);
                        }
                    },
                    // onPointerUp: ({ event, ...sharedState }) => console.log('pointer up', event),
                    // onPointerOver: ({ event, ...sharedState }) => console.log('pointer over', event),
                    // onDragEnd: ({ event, ...sharedState }) => console.log('drag end enter over req col', event.target.id),
                    // onHover: ({ event, ...sharedState }) => console.log('hover end enter over req col', event.target.id),
                    // onMouseUp: ({ event, ...sharedState }) => console.log('mouse up end enter over req col', event.target.id),


                });

    const [springsReqCol, apiReqCol] = useSprings(definedDataPoints.length, () => ({x:0, y: 0}));

    const bindReqCol = useGesture({
                        // onPointerOver: ({ event, ...sharedState }) => console.log('pointer over req col', event.target.id),
                            onHover: ({ event, ...sharedState }) => {
                                if(event.target.id){
                                    const findTargetDrop = definedDataPoints.find((el) => parseInt(el.id) === parseInt(event.target.id));
                                    // console.log('hover end enter over req col', event.target.id)
                                    console.log("Matching data", draggingElement.columnName, findTargetDrop.columnName)
                                }
                            }
                        // onMouseUp: ({ event, ...sharedState }) => console.log('mouse up end enter over req col', event.target.id, event.target),
                        });

    React.useEffect(() => {
        // console.log(springs, api)
        // if(subset.length > 0){
        //     setSubset((prevValues) => prevValues.map((v) => { return {...v, bind: useDrag(({ down, movement: [mx, my] }) => {
        //         api.start({ x: down ? mx : 0, y: down ? my : 0, immediate: down })
        //     }) } } ))
        // }
    },[]);

    const prevAmount = React.useCallback(() => {
        const start = showCurrentHeaders.startIndex - 1
        const end = showCurrentHeaders.endIndex - 1

        if (start < 0 || end > data.length) {
            return
        }

        setSubset(data.slice(start, end));
        setShowCurrentHeaders({ startIndex: start, endIndex: end })
    }, [data, subset, showCurrentHeaders]);

    const nextAmount = React.useCallback(() => {
        const start = showCurrentHeaders.startIndex + 1
        const end = showCurrentHeaders.endIndex + 1

        if (start < 0 || end > data.length) {
            return
        }

        setSubset(data.slice(start, end));
        setShowCurrentHeaders({ startIndex: start, endIndex: end })
    }, [data, subset, showCurrentHeaders]);

    return (
        <Card
            sx={{
                minWidth: "80vw",
                maxWidth: "85vw",
                minHeight: "30vh"
            }}
        >
            <CardHeader>

            </CardHeader>

            <CardContent>
                {/** Start of Cards Header */}
                <Box
                    sx={{
                        flexGrow: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center"
                    }}
                >
                    <Grid 
                        container 
                        direction="column"
                        justifyContent="space-evenly"
                    >
                        <Grid
                            container
                            spacing={{ xs: 2, md: 3 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                            display = "flex"
                            justifyContent = "center"
                            alignItems = "center"
                            alignContent = "center"
                            
                        >
                            <Grid>
                                <IconButton aria-label="prev" onClick={prevAmount}>
                                    <KeyboardArrowLeftIcon />
                                </IconButton>
                            </Grid>

                            {springs.map(({ x, y, rot, scale }, i) =>
                                <Grid key={subset[i].id}>
                                    <animated.div id={subset[i].id} {...bind(i)} key={subset[i].id}  style={{ x, y }}>
                                        <Card
                                            id={subset[i].id}
                                            key={subset[i].id}
                                            variant="outlined"
                                            sx={{
                                                minWidth: "17vw",
                                                minHeight: "10vh"
                                            }}
                                        >
                                            <CardContent>
                                                <Typography id={subset[i].id}>
                                                    {subset[i].columnName}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </animated.div>
                                </Grid>
                            )
                            }
                            <Grid>
                                <IconButton aria-label="next" onClick={nextAmount}>
                                    <KeyboardArrowRightIcon />
                                </IconButton>
                            </Grid>

                        </Grid>

                        <Grid
                            container
                            spacing={{ xs: 2, md: 3 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                            alignItems="flex-start"
                        >
                            {springsReqCol.map(({ x, y, rot, scale }, i) => 
                            <Grid key={definedDataPoints[i].id}>
                                <animated.div id={definedDataPoints[i].id} {...bindReqCol(i)} key={definedDataPoints[i].id}  style={{ x, y }}>

                                <Card
                                    id={definedDataPoints[i].id}
                                    key={definedDataPoints[i].id}
                                    variant="outlined"
                                    sx={{
                                        minWidth: "17vw",
                                        minHeight: "10vh"
                                    }}
                                >
                                    <CardContent>
                                        <Typography id={definedDataPoints[i].id}>
                                            {definedDataPoints[i].columnName}
                                        </Typography>
                                    </CardContent>
                                    {/* <DataCard 
                                        data={data}
                                    /> */}
                                </Card>
                                </animated.div>
                            </Grid>
                            
                            
                            )
                            }
                        </Grid>
                    </Grid>
                </Box>
                {/** End of Cards Header */}
            </CardContent>
            <CardActions>

            </CardActions>

        </Card>
    )
}

const DataCard = (props) => {
    const { data } = props;
    const [hoveredRef, isHovered] = useHover();

    return(
    <CardContent ref={hoveredRef}>
        <Typography>
            {data.columnName} - {isHovered ? "Yes" : "No"}
        </Typography>
    </CardContent>
    )
}

export default CSVHandler