import React from 'react';
import ReduxToastr from 'react-redux-toastr'
import Root from '../_components/_routes/routes';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import ServiceWorkerWrapper from '../_components/_serviceworkerwrapper/serviceWorkerWrapper';

const ColorModeContext = React.createContext({ 
    toggleColorMode: () => {},
    mode: null

});

export default function App() {

    const [cookies, setCookie]  = useCookies(['themeMode']);
    const [mode, setMode]       = React.useState(!cookies['themeMode'] ? 'dark' : cookies['themeMode']);
    const location              = useLocation();

    const toggleColorMode = () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
        setCookie('themeMode', mode === 'light' ? 'dark' : 'light', { path: '/' });
    }

    const lightModePalette = {
        mode,
        primary: {
            main   : '#940202',
            light  : '#EF9A9A',
            dark   : '#B71C1C',
            contrastText: '#ffffff',
        },
        secondary: {
            main   : '#ff8000',
            light  : '#ffa243',
            dark   : '#a95500',
            contrastText: '#000000',
        },
        grey:{
            50: '#fafafa',
            100: '#f5f5f5',
            200: '#eeeeee',
            300: '#e0e0e0',
            400: '#bdbdbd',
            "500": '#9e9e9e'
        },
        link: {
            active: '#EF6C00'
        },
        logo: {
            dark: '#ffffff',
        },
    }

    const darkModePalette = {
        mode,
        primary: {
            main: '#ffffff',
            light: '#F5F5F5',
            dark: '#E0E0E0',
            contrastText: '#000000',
        },
        secondary: {
            main   : '#EF6C00',
            light  : '#F57C00',
            dark   : '#E65100',
            contrastText: '#ffffff',
        },
        grey:{
            50: '#fafafa',
            100: '#f5f5f5',
            200: '#eeeeee',
            300: '#e0e0e0',
            400: '#bdbdbd',
            "500": '#9e9e9e'
        },
        link: {
            active: '#D32F2F'
        },
        logo: {
            dark: '#ffffff',
        },
    }

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: mode === 'light' ? lightModePalette : darkModePalette
            }),
        [mode],
    );

    React.useEffect(() => {
        // console.log(location.pathname)
        if(location.pathname !== "/join-us/contact-form"){
            const scriptToDelete = document.getElementById("tidiochat");
            // console.log(scriptToDelete)
            if (scriptToDelete) {
                document.head.removeChild(scriptToDelete);
            }
        }
    }, [location]);

    return (
        <ColorModeContext.Provider value={{toggleColorMode, palette: { mode }}}>
            <ThemeProvider theme={theme}>
                <ReduxToastr
                    timeOut={4000}
                    newestOnTop={false}
                    preventDuplicates
                    position="top-right"
                    getState={(state) => state.toastr} // This is the default
                    transitionIn="fadeIn"
                    transitionOut="fadeOut"
                    progressBar
                    closeOnToastrClick={false}
                />
                <ServiceWorkerWrapper />
                <Root />
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}

export const switchColorMode = () => React.useContext(ColorModeContext);
