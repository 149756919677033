import React from "react";
import { Button, Snackbar, SnackbarContent, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const CookieConsent = () => {
    const [showCookieConsent, setShowCookieConsent] = React.useState(false);

    React.useEffect(() => {
        const consented = localStorage.getItem('cookie-disclaimer');
        setShowCookieConsent(!consented);
    }, []);

    const acceptCookie = () => {
        localStorage.setItem("cookie-disclaimer", true);
        setShowCookieConsent(false);
    }

    return (
        <Snackbar
            open={showCookieConsent}
            message={

                <Stack sx={{ width: 500 }}>
                    <Typography variant="h6" >
                        We value your privacy
                    </Typography>

                    <Typography variant="subtitle1">
                        We use cookies to enhance your browsing experience, serve personalized ads or content, 
                        and analyze our traffic. By clicking "Accept" or staying on our site, you consent to our use of cookies. &nbsp;
                        <Typography
                            variant="subtitle1"
                            noWrap
                            component={Link}
                            to="/policies/cookiepolicy"
                        >
                        Cookie Policy
                        </Typography>
                    </Typography>
                </Stack>

                
            }
            data-test-id=""
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            action={
                <Button
                    color="inherit"
                    size="small"
                    onClick={acceptCookie}
                >
                    Accpet
                </Button>
            }
        />
    );

}


export default CookieConsent;
