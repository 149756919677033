import { authHeader, serviceAppAuth } from '../_helpers';
import { handleResponse } from './services.index';
import { config } from '../_helpers/apiConfig';

export const blogPostService = {
    create,
    approve,
    update
};


async function create(body, token) {
    
    const request = {
        method: 'POST',
        headers: { 
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    };

    const url = new URL(`${config.apiUrl}/database/blogposts/create`)
    // url.search = new URLSearchParams(params).toString();

    return fetch(url, request).then(handleResponse);
}

async function update(body, token, _id) {
    
    const request = {
        method: 'PATCH',
        headers: { 
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    };

    const url = new URL(`${config.apiUrl}/database/blogposts/${_id}`)
    // url.search = new URLSearchParams(params).toString();

    return fetch(url, request).then(handleResponse);
}

async function approve(body, token,_id) {
    
    const request = {
        method: 'POST',
        headers: { 
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    };

    const url = new URL(`${config.apiUrl}/database/blogposts/apporve/${_id}`)
    // url.search = new URLSearchParams(params).toString();

    return fetch(url, request).then(handleResponse);
}



