import React from "react";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { useDispatch, useSelector } from "react-redux";
import { publicUserActions } from "../../../_redux/_actions/publicuser.actions";
import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import {stateToHTML} from 'draft-js-export-html';

import { Box } from "@mui/system";
import LogoutIcon from '@mui/icons-material/Logout';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import BlogProfile from "./BlogProfile";
import SaveIcon from '@mui/icons-material/Save';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { blogPostService } from "../../../_services/blogposts.services";
import { publicSocket } from "../../../_services/services.index";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="outlined" {...props} />;
  });
  

export const BlogOptions = {
    inlineStyleFn: (styles) => {
      let key = 'color-';
      let color = styles.filter((value) => value.startsWith(key)).first();
   
      if (color) {
        return {
          element: 'span',
          style: {
            color: color.replace(key, ''),
          },
        };
      }
    },
    entityStyleFn: (entity) => {
        const entityType = entity.get('type').toLowerCase();
        if (entityType === 'image') {
          const data = entity.getData();
          return {
            element: 'img',
            attributes: {
              src: data.src,
            },
            style: entity.data.alignment !== "none" ? 
            {
              // Put styles here...
              display      : "block",
              marginLeft   : "auto",
              marginRight  : "auto",
              height       : "auto",
              maxWidth     : "100%",
              clear        : "both",
              position     : "relative"
            }
            : 
            {
                display      : "block",
                marginLeft   : "auto",
                marginRight  : "auto",
                height       : "auto",
                maxWidth     : "100%"
            }            
            ,
          };
        }
      },
      blockStyleFn: (block) => {
          
        const alignment = block.getData().get('text-align');
        if (typeof alignment == 'string') {
            return {
                style: {
                    textAlign: alignment
                }
            };
        }

        return null;
      }
};

const BlogActions = (props) => {

    const { setConvertedContent, setAddingNewPost, addingNewPost } = props;

    const publicuser                    = useSelector(state => state.publicuser.user);
    const dispatch                      = useDispatch();

    const [editorState, setEditorState] = React.useState(() =>  EditorState.createEmpty());

    const onChange = (newState) => {
        setEditorState(newState)
        const html = stateToHTML(newState.getCurrentContent(), BlogOptions);
        setConvertedContent(html);

    }

    React.useEffect(() => {
        if(addingNewPost === false){
            setEditorState(() => EditorState.createEmpty())
        }
    },[addingNewPost])

    /**
     * Handle SnackBar
     */
    const [sbState, setsbState] = React.useState({
        triggered: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: 'success'
      });
      const { vertical, horizontal, triggered, message, severity } = sbState;

      const handlesbClick = (newState) => {
        setsbState({ triggered: true, ...newState });
      };
    
      const handlesbClose = () => {
        setsbState({ ...sbState, triggered: false });
      };

      const acknowledge = (
        <Button color="primary" size="small" onClick={handlesbClose}>
          ok
        </Button>
      );


          /**
     * Store Editor State in the Cloud & Place the HTML
     */
    const savePost = React.useCallback(async () => {
        try{
            const contentState = editorState.getCurrentContent();
            const contentStateData = JSON.stringify(convertToRaw(contentState))
            console.log(contentStateData);

            const payload = {
                contentState:            contentStateData,
                publicUserID:            publicuser.id,
                anonUser:                publicuser.anonymousName,
            }

            const token = publicuser.accessToken;
            await blogPostService.create(payload, token);
            setAddingNewPost(false);
            /**When Saved success */
            handlesbClick({
                message: 'Data Saved', 
                severity: 'success',
                vertical: 'top',
                horizontal: 'center',
            })
        }catch(err){
            console.log(err)
            handlesbClick({
                message: 'Unable to store Blog Post. Contact Support', 
                severity: 'error',
                vertical: 'top',
                horizontal: 'center',
            })
        }
    },[editorState]);

    return(

        <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
        >
            <Grid
                item
                container
                justifyContent="space-between"
            >
                    <Grid item>
                        <Tooltip title="Logout">
                            <IconButton
                                onClick={() => {  dispatch(publicUserActions.logout()); publicSocket.emit('watchblogposts'); publicSocket.emit('endwatchpublicuser');}}
                            >
                                <LogoutIcon/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    {addingNewPost ?
                    <>
                        <Grid item>
                            <Tooltip title="Save Post">
                                <IconButton
                                    onClick={() => {savePost()}}
                                >
                                    <SaveIcon/>
                                </IconButton>
                            </Tooltip>

                        </Grid>
                        <Grid item>
                            <Tooltip title="Exit Post">
                                <IconButton
                                    onClick={() => {setAddingNewPost(false)}}
                                >
                                    <CloseIcon/>
                                </IconButton>
                            </Tooltip>

                        </Grid>
                    </>

                    :
                    <Grid item>
                        <Tooltip title="Create Post">
                            <IconButton
                                onClick={() => {setAddingNewPost(true)}}
                            >
                                <AddIcon/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    }

            </Grid>

            <Grid
                item
                xs={12}
            >
                <Box>
                    <Snackbar
                        anchorOrigin={{ vertical, horizontal }}
                        open={triggered}
                        onClose={handlesbClose}
                        // message={message}
                        key={vertical + horizontal}
                    >
                        <Alert severity={severity} action={acknowledge}>{message}</Alert>
                    </Snackbar>
                    {addingNewPost &&
                    
                    <Editor 
                        editorState={editorState} 
                        onEditorStateChange={onChange}
                        toolbar={{
                            options: ['inline', 'list', 'image', 'emoji', 'textAlign', 'link', 'colorPicker', 'history', 'fontSize', 'fontFamily' ],
                            image: {
                                // icon: image,
                                className: undefined,
                                component: undefined,
                                popupClassName: undefined,
                                urlEnabled: true,
                                uploadEnabled: true,
                                alignmentEnabled: false,
                                uploadCallback: undefined,
                                previewImage: true,
                                inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                alt: { present: false, mandatory: false },
                                defaultSize: {
                                  height: 'auto',
                                  width: 'auto',
                                },
                            },
                        }}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"  
                    />
                    }

                    {!addingNewPost &&
                        <BlogProfile/>
                    }
                </Box>
            </Grid>

        </Grid>

    )
}


export default BlogActions