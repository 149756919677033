import React from 'react';
import Layout from "../../_components/_publicsite/common/Layout";
import BeeadCrumb from "../../_components/_publicsite/elements/breadCrumb/BreadCrumb";
import SectionTitle from "../../_components/_publicsite/elements/sectionTitle/SectionTitle";
import ContactOne from "../../_components/_publicsite/elements/contact/ContactOne";
import SEO from '../../_components/_publicsite/common/SEO';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';

const Contact = () => {
    return (
        <React.Fragment>
            <SEO title="Contact Us - RFC" />
            <BeeadCrumb
                title="Contacting us is free"
                subtitle="Get a quote & go #GigLife"
                rootUrl="/"
                parentUrl="Home"
                currentUrl="Contact"
            />


            <Box sx={{ color: 'white', pt: "50px", pb: "100px" }}>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                    spacing={1}
                >
                    <Grid item xs={12} md={6} sm={4}>
                        <SectionTitle
                            textAlign="text-center"
                            radiusRounded=""
                            subtitle="Get in touch"
                            title="We'll get back to you as soon as possible!"
                            description=""
                        />
                    </Grid>
                    <Grid item>
                        <ContactOne />
                    </Grid>
                    {/* End Contact Area  */}
                </Grid>
            </Box>
        </React.Fragment>
    )
}
export default Contact;