import React from 'react';
import ContactForm from "./ContactForm";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import GoogleMapStyle from './ContactMapStyle';


import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ContactCard from '../cards/contactCards';
import { Grid } from '@mui/material';

const ContactOne = () => {
    return (
        <React.Fragment>
            <Grid
                container
                alignItems={"center"}
                justifyContent="center"
                spacing={3}
            >

                <Grid item>
                    <ContactCard
                        contactTitle = {"Want to hire us?"}
                        department = {"Sales"}
                        email = {"sales@remotefreedomconnect.com"}
                        phone = "+502 3048-5080"
                    />
                </Grid>
                <Grid item>
                    <ContactCard
                        contactTitle = {"Applying to our Gigs & need help?"}
                        department = {"Sourcing"}
                        email = {" join@remotefreedomconnect.com"}
                        phone = "+502 3049-0446"
                    />
                </Grid>

                <Grid 
                    item
                    container
                    spacing={1}
                    sx={{
                        mt: 5
                    }}
                    display="flex"
                    justifyContent="center"
                >
                    <Grid item xs={10} md={5} sm={4}>
                        <ContactForm formStyle="contact-form-1" />
                    </Grid>
                    <Grid item xs={10} md={5} sm={4}>
                        <GoogleMapStyle />
                    </Grid>
                </Grid>


            </Grid>

        </React.Fragment>
    )
}
export default ContactOne;