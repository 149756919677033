import { Avatar, Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material';
import React from 'react';

import { useLinkedIn } from 'react-linkedin-login-oauth2';
// You can use provided image shipped by this package or using your own
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { publicusersService } from '../../../_services/publicusers.service';
import { linkedinsService } from '../../../_services/linkedin.services';
import { setPhone } from '../../../_components/Formik/CustomFields/InputHelpers';
import { useFormik } from 'formik';
import * as yup from 'yup';
import MuiPhoneNumber from 'material-ui-phone-number-2';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { publicUserActions } from '../../../_redux/_actions/publicuser.actions';
import { CheckBox } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { publicSocket } from '../../../_services/services.index';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

const roles = [
  {
    id: 0,
    name: "-"
  },
  {
    id: 1,
    name: "Front Line Member"
  },
  {
    id: 2,
    name: "Gig Worker"
  },
  {
    id: 3,
    name: "Supervisor"
  },
  {
    id: 4,
    name: "Entry Level Manager"
  },
  {
    id: 5,
    name: "Sr. Manager"
  },
  {
    id: 7,
    name: "Leadership"
  },
  {
    id: 8,
    name: "C-Level"
  },
  {
    id: 9,
    name: "Founder"
  },
  {
    id: 10,
    name: "Chair Man"
  }
]

const industries = [
  { id: 0, name: "-" }, 
  { id: 1, name: "Agriculture, forestry and fishing" }, 
  { id: 2, name: "Mining and quarrying" }, 
  { id: 3, name: "Manufacturing" }, 
  { id: 4, name: "Electricity, gas, steam and air conditioning supply" }, 
  { id: 5, name: "Water supply; sewerage, waste management and remediation activities" }, 
  { id: 6, name: "Construction" }, 
  { id: 7, name: "Wholesale and retail trade; repair of motor vehicles and motorcycles" }, 
  { id: 8, name: "Transportation and storage" }, 
  { id: 9, name: "Accommodation and food service activities" }, 
  { id: 10, name: "Information and communication" }, 
  { id: 11, name: "Financial and insurance activities" }, 
  { id: 12, name: "Real estate activities" }, 
  { id: 13, name: "Professional, scientific and technical activities" }, 
  { id: 14, name: "Administrative and support service activities" }, 
  { id: 15, name: "Public administration and defence; compulsory social security" }, 
  { id: 16, name: "Education" }, 
  { id: 17, name: "Human health and social work activities" }, 
  { id: 18, name: "Arts, entertainment and recreation" }, 
  { id: 19, name: "Other service activities" }, 
  { id: 20, name: "Activities of households as employers; undifferentiated goods- and services-producing activities of households for own use" }, 
  { id: 21, name: "Activities of extraterritorial organizations and bodies"}, 
  { id: 22, name: "Technology; FinTech, Cybersecurity or Data Science" }
]

const SignInWithLinkedIn = () => {

  const dispatch = useDispatch();
  const appInsights = useAppInsightsContext();

  const { linkedInLogin } = useLinkedIn({
    clientId: '86un2ylze9daoo',
    redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: (code) => {
      // console.log(code);
      login(code)
    },
    scope: "r_emailaddress r_liteprofile profile email openid",
    onError: (error) => {
      console.log(error);
    },
  });

  const [createLoading, setCreateLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [createData, setCreateData] = React.useState({});

  const login = React.useCallback(async (code, data) => {
    try {
      setLoading(true);
      let responseLinkedIn
      let emailAddress

      if (!data) {
        const response = await linkedinsService.fetchUserInfo({ code: code });
        responseLinkedIn = response.data;
        emailAddress = responseLinkedIn.email
        setCreateData(responseLinkedIn);
      } else {
        responseLinkedIn = data;
        emailAddress = responseLinkedIn.emailAddress
      }

      // console.log(responseLinkedIn);
      const responseLogin = await publicusersService.login({ emailAddress: emailAddress });

      /**
       * @TODO DISPATCH
       */
      // console.log("Creation.", responseLogin.data)
      setLoading(false);
      publicSocket.emit("watchblogposts", { _id: responseLogin?.data?._id });
      publicSocket.emit("watchpublicuser", { _id: responseLogin?.data?._id });

      dispatch(publicUserActions.login({ ...responseLogin.data}));
    } catch (err) {
      // IF the error is none found, create.
      if (err.status === 404 && err.data.message === "None Found.") {
        setCreateLoading(true);
        setLoading(true);
      }

    }
  }, []);

  /**
   * FORMIK
   */

  const [validationSchemaData, setValidationSchemaData] = React.useState(
    {
      phoneNumber: yup
        .string().min(1).required('Phone Number is required.'),
      companyName: yup
        .string().min(1).required('Company name is required.'),
      role: yup
        .mixed().notOneOf(["-"], "Role is a must")
        .required("We require this information"),
      industry: yup
        .mixed().notOneOf(["-"], "Industry is a must")
        .required("We require this information"),
      roleName: yup
        .string().min(1).required('Role Name is required.'),
      tos: yup
        .bool().oneOf([true], 'Field must be checked'),
    },
  );

  const validationSchema = yup.object(validationSchemaData);

  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
      countryCode: '',
      roleName: '',
      short: '',
      country: '',
      role: '-',
      companyName: '',
      industry: '-',
      tos: false
    },
    validationSchema: validationSchema,
    validateOnChange: true, // this one
    validateOnBlur: true, // and this one
    onSubmit: (values) => {
      createUser(values)
    },
  });

  const createUser = React.useCallback(async (data) => {

    try {

      const create = {
        emailAddress: createData.email,
        firstName: createData.given_name,
        lastName: createData.family_name,
        phoneNumber: data.phoneNumber.split(' ')[1],
        picture: createData.picture,
        country: data.country,
        countryCode: data.countryCode,
        role: data.role,
        companyName: data.companyName,
        roleName: data.roleName,
        industry: data.industry,
        tos: data.tos
      }

      // console.log(create)
      const responseCreate = await publicusersService.create(create);

      const insightsData = { ...responseCreate.data, source: "linkedin" }

      appInsights.trackEvent(
          {
              name: "Public User Account Creation", 
              properties: insightsData
          }
      )

      login(null, responseCreate.data);
      setCreateLoading(false);
    } catch (err) {
      setCreateLoading(false);
      setLoading(false);
    }

  }, [createData]);

  /**
   * FORMIK END
   */

  const ComplementData = (

    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{maxWidth: "50vw"}}
    >
      <Grid item>
        <Typography color="secondary.contrastText">Welcome! We need a few more things from you!</Typography>

        <form onSubmit={formik.handleSubmit} className="form" >
          <Grid container spacing={2} columns={2}>
            <Grid item xs={10}>
              <MuiPhoneNumber
                fullWidth
                defaultCountry='us'
                variant="outlined"
                regions={['america', 'central-america']}
                value={formik.values.phone}
                onChange={(value, country) => setPhone(value, country, formik)}
                error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                size="small"
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                select
                size="small"
                fullWidth
                id="role"
                name="role"
                label="What is your Role?"
                value={formik.values.role}
                onChange={formik.handleChange}
                error={formik.touched.role && Boolean(formik.errors.role)}
                helperText={formik.touched.role && formik.errors.role}
                sx={{maxWidth: "50vw"}}
                >
                {roles.map((option, index) => (
                  <MenuItem key={index} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={10}>
              <TextField
                size="small"
                fullWidth
                id="roleName"
                name="roleName"
                label="Business Title"
                type={"text"}
                value={formik.values.roleName}
                onChange={formik.handleChange}
                error={formik.touched.roleName && Boolean(formik.errors.roleName)}
                helperText={formik.touched.roleName && formik.errors.roleName}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                size="small"
                fullWidth
                id="companyName"
                name="companyName"
                label="Company Name"
                type={"text"}
                value={formik.values.companyName}
                onChange={formik.handleChange}
                error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                helperText={formik.touched.companyName && formik.errors.companyName}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                select
                fullWidth
                size="small"
                id="industry"
                name="industry"
                label="What industry?"
                value={formik.values.industry}
                onChange={formik.handleChange}
                error={formik.touched.industry && Boolean(formik.errors.industry)}
                helperText={formik.touched.industry && formik.errors.industry}
                sx={{maxWidth: "50vw"}}
                >
                {industries.map((option, index) => (
                  <MenuItem key={index} value={option.name} style={{whiteSpace: 'normal'}}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={10}>
              <FormControl
                required
                error={formik.touched.tos && Boolean(formik.errors.tos)}
                component="fieldset"
                variant="standard"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.tos}
                      onChange={formik.handleChange}
                      name="tos"
                    />
                  }
                  label={
                    <div style={{ fontSize: "6px" }}>
                      <Typography component="span">I accept the </Typography>
                      <Typography component={Link} to={'/policies/terms'} target="_blank">terms</Typography>
                      <Typography component="span">  &amp; </Typography>
                      <Typography component={Link} to={'/policies/privacy'} target="_blank">privacy policy</Typography>
                    </div>
                  }
                />
                <FormHelperText>{formik.touched.tos && formik.errors.tos}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={10}>
              <Button color="primary" variant="contained" fullWidth type="submit">
                Submit Data
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  )


  return (
    <React.Fragment>
      {/* {JSON.stringify(createData)} */}
      {createLoading &&
        ComplementData
      }
      {!createLoading &&
        <Grid
          container
          textAlign={"center"}
        >
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
          >
            <LoadingButton
              onClick={linkedInLogin}
              startIcon={<LinkedInIcon />}
              loading={loading}
            >
              Sign in
            </LoadingButton>
          </Grid>
        </Grid>
      }
    </React.Fragment>

  );

}


export default SignInWithLinkedIn