

import React from "react";
import { ResponsiveRadialBar } from '@nivo/radial-bar';

const data = [

    {
        "id": "#GigLife",
        "data": [
          {
            "x": "Net Income #GigLife",
            "y": 7030
          },
          {
            "x": "IVA",
            "y": 370
          },
        ]
    },
    {
        "id": "Regular Customer Service Job",
        "data": [
          {
            "x": "Net Income Regular Job",
            "y": 5081.44
          },
          {
            "x": "Local Health Insurance",
            "y": 260.82
          },
          {
            "x": "ISR",
            "y": 103.33
          },
        ]
    },
]

const CompareYourIncome = (props) => {
    
    const { theme } = props
    const dark = theme.palette.mode === "dark"
    
    return (
        <ResponsiveRadialBar
            data={data}
            valueFormat=">-.2f"
            padding={0.4}
            cornerRadius={2}
            margin={{ top: 40, right: 120, bottom: 40, left: 40 }}
            enableTracks={false}
            radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
            circularAxisOuter={{ tickSize: 5, tickPadding: 12, tickRotation: 0 }}
            theme={{
                textColor: dark ?  "#ffffff" : "#000000",
                tooltip: {
                    "container": {
                        "background": "#ffffff",
                        "color": "#333333",
                        "fontSize": 12
                    },
                    "basic": {},
                    "chip": {},
                    "table": {},
                    "tableCell": {},
                    "tableCellValue": {}
                }
            }}
            colors={{ scheme:"red_yellow_green"}}

            legends={[
                {
                    anchor: 'right',
                    direction: 'column',
                    justify: false,
                    translateX: 80,
                    translateY: 0,
                    itemsSpacing: 6,
                    itemDirection: 'right-to-left',
                    itemWidth: 100,
                    itemHeight: 18,
                    itemTextColor: dark ?  "#ffffff" : "#000000",
                    symbolSize: 18,
                    symbolShape: 'square',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#D32F2F'
                            }
                        }
                    ]
                }
            ]}
        />
    )
}


export default CompareYourIncome