import { authHeader } from '../_helpers';
import { handleResponse } from './services.index';

// const config =  {
//     apiSocketURL: process.env.apiSocketUrl,
//     apiUrl: process.env.apiUrl
// }

import { config } from '../_helpers/apiConfig';



export const customizeService = {
    UploadCorporateLogo,
    DeleteCorporateLogo,
};

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

async function UploadCorporateLogo(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return await fetch(`${config.apiUrl}/customize/uploadcorpoatelogo`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // Update the user data through dispatch.
            return data;
        });
}

function DeleteCorporateLogo(corporateEmail, password) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ corporateEmail, password })
    };

    return fetch(`${config.apiUrl}/users/authenticate`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}
