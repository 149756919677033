import React from "react";
import { Button, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import SignInWithLinkedIn from "../ManageBlog/Login";
import Typist from 'react-typist-component';
import { useDispatch, useSelector } from "react-redux";
import { publicUserActions } from "../../../_redux/_actions/publicuser.actions";
import BlogActions from "../ManageBlog/BlogActions";


const EnterBlog = (props) => {

    const publicuser      = useSelector(state => state.publicuser.user);

    return(
        <Grid 
            item
            container
            direction="column"
            justifyContent="flex-end"
            alignItems="center"
        >
            <Card 
                sx={{
                    minHeight: "120%",
                    width: "60vw",
                }}
            >
                <CardContent>
                {!publicuser &&
                    <Grid
                        container
                        direction="column"
                        spacing={2}
                        alignItems= "center"
                    >
                            <Grid
                                item
                                container
                                textAlign= "center"
                                justifyContent={"center"}
                            >
                                <Grid>
                                    <Typist typingDelay={100} cursor={<span className='cursor'> |</span>}>
                                        <Typography className="theme-gradient">Login to post, comment & reply</Typography>
                                    </Typist>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                            >
                                <SignInWithLinkedIn/>
                            </Grid>
                    </Grid>
                }
                {publicuser && 
                    <BlogActions {...props}/>
                }
                    
                </CardContent>
            </Card>
        </Grid>
    )
}

export default EnterBlog