import React from 'react';
import { FiArrowRight } from "react-icons/fi";
import SEO from "../../_components/_publicsite/common/SEO";
import Header from '../../_components/_publicsite/common/header/header';
import TabOne from '../../_components/_publicsite/elements/tab/TabOne';
import SectionTitle from "../../_components/_publicsite/elements/sectionTitle/SectionTitle";
import SlipFour from "../../_components/_publicsite/elements/split/SplitFour";
import SlpitOne from "../../_components/_publicsite/elements/split/SplitOne";
import AdvancePricingOne from "../../_components/_publicsite/elements/advancePricing/AdvacnedPricingOne";
import Footer from '../../_components/_publicsite/common/footer/Footer';
import Copyright from '../../_components/_publicsite/common/footer/Copyright';
import Separator from "../../_components/_publicsite/elements/separator/Separator";
import CssBaseline from '@mui/material/CssBaseline';

import BlogList from "../../_components/_publicsite/components/blog/itemProp/blogList";
import BlogClassicData from '../../_components/_publicsite/data/blog/BlogList.json';
var BlogListData = BlogClassicData.slice(0, 3);
// import styles from '../../_styles/_publicsite/assets/scss/style.scss';
import { 
    Popover, 
    Box, 
    Button, 
    Container, 
    Typography, 
    Grid, 
    Card, 
    CardMedia, 
    CardContent, 
    Divider, 
    Stack, 
    CardActionArea 
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ParticlesEngine from '../../_components/_publicsite/elements/particles/particles';
import CountUp from 'react-countup';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import image from './media/homemedia/central-america-map.png'
import remotework from './media/whyus/remote-work.jpeg'
import pointdown from './media/whyus/pointing-down.jpeg'
import talent from './media/whyus/talent.jpeg'
import rocket from './media/whyus/rocket.jpeg'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ReactCardFlip from "react-card-flip";

const CardNumber = (props) => {
    const { title, endNumber, prefix, suffix } = props;

    return (
        <React.Fragment>
            <CardContent>

                <Typography gutterBottom variant="h5" className="theme-gradient" component="div">
                    {title}
                </Typography>

                <CountUp start={0} end={endNumber} delay={0} prefix={prefix} suffix={suffix}>
                    {({ countUpRef }) => (
                        <React.Fragment>
                            <Typography sx={{ fontSize: 18 }} ref={countUpRef}>
                            </Typography>
                        </React.Fragment>
                    )}
                </CountUp>

            </CardContent>
            {/* <CardActions>
            <Button size="small">Learn More</Button>
        </CardActions> */}
        </React.Fragment>
    )
};


function createData(name, avgEarnings, avgHoursWorked, otherIncentives, highestEarning, startingPrice) {
    return { name, avgEarnings, avgHoursWorked, otherIncentives, highestEarning, startingPrice };
}

const rows = [
    createData('VXI', "$592", 8, "$157", "$894", "6.40%"),
    createData('Intouch CX', "$592", 8, "$157", "$921", "17.60%"),
    createData('Telus International', "$592", 8, "$184", "$960", "-4.80%"),
    createData('Conduent', "$657", 8, "$263", "$986", "-4.80%"),
    createData('Alorica', "$592", 8, "$157", "$921", "-4.80%"),
    createData('Atento', "$592", 8, "$157", "$894", "-4.80%"),
    // createData('Everise', "$592", 8, "$276", "$789", "$17"),
    createData('HCL', "$592", 8, "$131", "$921", "-4.80%"),
    createData('Remote Freedom Connect', "$480", 4, "$26.32", "$960", "-7.60%"),
];

const whatMakesUsDifferent = [

    {
        image: remotework,
        description: "With multiple Contractor nodes & talent across the country; we increase business continouity, reduce commute hours, & provide a work-life balance through our \"minimum hours\" platform",
        footer: "Remote Work"
    },
    {
        image: pointdown,
        description: "Our relationship is \"ESSI\", honest, & transparent. We always give you the most competitive prices in the market (contrary) our competitors believe in a benefits system that is getting old",
        footer: "Lower Cost..."
    },
    {
        image: talent,
        description: "We have a candidate pool that includes numerous Champions who have previously worked for the top BPO providers and represented some of the world's most renowned brands. When you choose us, you get the best in the business at a more competitive price",
        footer: "...Same Talent"
    },
    {
        image: rocket,
        description: "We are not alone, Reporing Pal has offered clients up to 4x & 10x capabilities by leveraging \"Cloud micro-processes\" that no longer require manual intervention; our relationship however goes beyond \"just clients\" allowing us access to the cutting edge technology",
        footer: "Technology Driven"
    }
]    

import InfoIcon from '@mui/icons-material/Info';

const PublicSiteHome = () => {

    const navigate = useNavigate();

    return (

        <React.Fragment>
            <SEO title="Remote Freedom Connect BPO" />

            <Box
                sx={{
                    bgcolor: "#393939",
                    height: "600px",
                }}
            >

                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                    sx={{
                        minHeight: "60vh",
                    }}
                >
                    <Grid item sx={{ zIndex: 1 }}>
                        <Typography variant="h2" color="white">Remote
                            <br />
                            <Typography variant="h2" className="theme-gradient" component="span">Business </Typography> &
                            <Typography variant="h2" component="span" className="theme-gradient"> Process</Typography>
                            <br />Outsourcing
                        </Typography>
                        <Typography color="white" variant="subtitle1">We enable Central America's top talent through our <Typography className="theme-gradient" sx={{ fontStyle: 'italic' }}>"ESSI"</Typography> program</Typography>
                    </Grid>
                    <Grid item sx={{ zIndex: 1, mt: 5 }}>
                        <Button
                            onMouseDown={(e) => { e.preventDefault() }}
                            onClick={() => { navigate("/contact") }}
                            sx={{
                                color: "white",
                                borderColor: "white"
                            }}
                            variant="outlined"
                            endIcon={<FiArrowRight />}
                        >
                            Contact Us
                        </Button>
                    </Grid>

                    <div
                        style={{
                            position: "absolute",
                            overflow: "hidden",
                            width: "100%",
                        }}
                    >
                        <ParticlesEngine />

                    </div>


                </Grid>

            </Box>

            <Box sx={{
                color: 'white',
                pt: 20,
                pb: 0
            }}>
                <Container>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                    >

                        <div
                            style={{
                                position: "absolute",
                                overflow: "hidden",
                                width: "100%",

                            }}
                        >
                            <img
                                src={image}
                                alt="image" style={{ width: "50rem", zIndex: 100 }}
                            />
                        </div>
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            textAlign="center"
                            sx={{
                                zIndex: 1
                            }}
                        >
                            <Grid item xs={12}>
                                <Typography
                                    variant="h3"
                                    color="secondary.contrastText"
                                    component="span"
                                    sx={{}}
                                >
                                    Our Champions numbers speak
                                    <br />
                                    <Typography variant="h3" className="theme-gradient" component="span">louder </Typography>
                                    <br />
                                    than words
                                </Typography>
                            </Grid>

                            <Grid
                                item xs={12}
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Divider sx={{
                                    width: "10%",
                                    padding: 1
                                }}
                                >

                                </Divider>
                            </Grid>

                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <Typography sx={{ fontStyle: 'italic' }} component="span" variant="subtitle1" color={"primary"}>
                                    We take our job <Typography component="span" sx={{ textDecoration: "underline" }} >seriously</Typography>
                                </Typography>
                            </Grid>

                            <Grid item sx={{ mt: 5, mb: 10 }}>
                                <Grid
                                    direction="row"
                                    spacing={2}
                                    container
                                    display={"flex"}
                                    justifyContent="center"
                                    alignItems="center"
                                    alignContent={"center"}
                                >
                                    <Grid
                                        item
                                    >
                                        <Card
                                            sx={{
                                                width: "275px",
                                                height: "150px",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                display: "flex"
                                            }}
                                        >
                                            <CardNumber title={"Hours Delivered"} endNumber={15171.22} suffix={" YTD"}/>
                                        </Card>

                                    </Grid>
                                    <Grid
                                        item
                                    >
                                          
                                        <Card
                                            sx={{
                                                width: "275px",
                                                height: "150px",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                display: "flex"
                                            }}
                                        >
                                            <CardNumber title={"Paid Earnings"} endNumber={101808.94} prefix={"$"} suffix={" YTD"}/>
                                        </Card>

                                    </Grid>
                                    <Grid
                                        item
                                    >
                                        <Card
                                            sx={{
                                                width: "275px",
                                                height: "150px",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                display: "flex"
                                            }}
                                        >
                                            <CardNumber title={"Talent Pool"} endNumber={40000} prefix={"+ "} suffix={" contractors"} />
                                        </Card>

                                    </Grid>
                                    <Grid
                                        item
                                    >

                                        <Card
                                            sx={{
                                                width: "275px",
                                                height: "150px",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                display: "flex"
                                            }}
                                        >
                                            <CardNumber title={"Avg. Applicants"} prefix={"+ "} suffix={" per post "}  endNumber={150} />
                                        </Card>

                                    </Grid>
                                </Grid>
                                    {/**@TODO GET metrics and link them. */}
    

                            </Grid>

                            <Grid
                                item xs={12}
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Divider sx={{
                                    width: "100%",
                                    padding: 1
                                }}
                                >

                                </Divider>
                            </Grid>

                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box sx={{
                color: 'white',
                pt: 0,
                pb: 10
            }}>
                <Container>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                    >
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            textAlign="center"
                            sx={{
                                zIndex: 1
                            }}
                        >
                            <Grid item xs={12} sx={{ mt: 10, mb: 5 }}>
                                <Typography sx={{ fontStyle: 'italic' }} component="span" variant="subtitle1" color={"primary"}>
                                    We may not pay the most but we build <Typography component="span" sx={{ textDecoration: "underline" }}>quality & trust</Typography>
                                    <br />
                                    to both our Champions & Clients
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="comparison-industries">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Company</TableCell>
                                                <TableCell align="right">Average Earning (Lowest)</TableCell>
                                                <TableCell align="right">Work Hours (lowest)</TableCell>
                                                <TableCell align="right">Other Incentives</TableCell>
                                                <TableCell align="right">Average Earning (Highest)</TableCell>
                                                <TableCell align="right">Price Percent Benchmark {priceInfromation()}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{
                                                        '&:last-child td, &:last-child th': { border: 0 },
                                                        backgroundColor: row.name === "Remote Freedom Connect" ? "green" : "",
                                                        ".MuiTableCell-root": { color: row.name === "Remote Freedom Connect" ? "white" : "" },

                                                    }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell align="right">{row.avgEarnings}</TableCell>
                                                    <TableCell align="right">{row.avgHoursWorked}</TableCell>
                                                    <TableCell align="right">{row.otherIncentives}</TableCell>
                                                    <TableCell align="right">{row.highestEarning}</TableCell>
                                                    <TableCell align="right">{row.startingPrice.split('%')[0] < 0? <ArrowDownwardIcon/> : <ArrowUpwardIcon/>} {row.startingPrice}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>

                        </Grid>
                    </Grid>
                </Container>
            </Box>

            {/* <Box sx={{ color: 'white', pt: 5, pb: 20 }}>
                <Container>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                    >
                        <Grid item xs={12}>
                            <SectionTitle
                                textAlign="text-center"
                                radiusRounded=""
                                subtitle="Remote Freedom Connect"
                                title="Welcome to the #GigLife"
                                description=""
                            />

                        </Grid>

                        <Grid item>
                            <TabOne />
                            {/* <div 
                                style={{
                                    position: "relative", 
                                    width: "100%", 
                                    height: "0", 
                                    paddingTop: "141.4286%",
                                    paddingBottom: 0, 
                                    boxShadow: "0 2px 8px 0 rgba(63,69,81,0.16)" ,
                                    marginTop: "1.6em",
                                    marginBottom: "0.9em",
                                    overflow: "hidden",
                                    borderRadius: "8px",
                                    willChange: "transform",
                                }}
                            >
                                <iframe loading="lazy" 
                                    style={{
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                        top: 0,
                                        left: 0,
                                        border: "none",
                                        padding: 0,
                                        margin: 0,
                                    }}
                                    src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFfna2xft8&#x2F;view?embed">
                                </iframe>
                            </div>  /}

                        </Grid>
                    </Grid>
                </Container>


            </Box> */}

            <Box
                sx={{
                    color: 'white',
                    pt: 0,
                    pb: 2
                }}
            >
                <Container>

                    <Grid
                        container  
                        justifyContent="center"
                        alignItems="center"
                
                    >
                        <Grid item xs={12}>
                            <Typography
                                variant="h3"
                                color="secondary.contrastText"
                                component="span"
                                sx={{}}
                            >
                                What makes us
                                <br />
                                <Typography variant="h3" className="theme-gradient" component="span">different </Typography>
                                <br />
                            </Typography>
                        </Grid>

                        <Grid
                            item xs={12}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Divider sx={{
                                width: "100%",
                                padding: 1,
                                mb: 5
                            }}
                            >

                            </Divider>
                        </Grid>

                        <Grid 
                            item 
                        >
                            <Grid 
                                direction="row"
                                spacing={4}
                                container
                                display={"flex"}
                                justifyContent="center"
                                alignItems="center"
                            >

                            {
                                whatMakesUsDifferent.map((v, index) => 
                                    <Grid
                                        key={index} 
                                        item
                                    >
                                        <CardFlip 
                                            title={v.footer} 
                                            image={v.image}
                                            description={v.description}
                                        />
                                    
                                    </Grid>
                                )
                            }
                        
                            </Grid>
                        </Grid>

                        <Grid
                            item xs={12}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Divider sx={{
                                width: "100%",
                                padding: 1,
                                mt: 5
                            }}
                            >

                            </Divider>
                        </Grid>
                        

                        <Grid
                            item
                            container
                            textAlign={"center"}
                        >
                            <Grid item xs={12} sx={{ mt: 10, mb: 5 }}>
                                    <Typography sx={{ fontStyle: 'italic' }} component="span" variant="subtitle1" color={"primary"}>
                                        Still not impressed?
                                        <br />
                                        checkout what we've done
                                    </Typography>
                            </Grid>
                        </Grid>
                    </Grid>


                </Container>
            </Box>

            <Box
                sx={{
                    color: 'white',
                    pt: 0,
                    pb: 10
                }}
            >
                <Container>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"                    
                    >
                            <Grid item>
                                <Button
                                    onMouseDown={(e) => { e.preventDefault() }}
                                    onClick={() => { navigate("case-study/1") }}
                                    sx={{
                                        color: "primay",
                                        borderColor: "primay"
                                    }}
                                    variant="outlined"
                                    endIcon={<FiArrowRight />}
                                >
                                    Case Study
                                </Button>
                            </Grid>

                    </Grid>

                </Container>
            </Box>

        </React.Fragment>

    )
}

export default PublicSiteHome
         

const CardFlip = (props) => {
    const { title, image, description } = props
    const [flip, setFlip] = React.useState(false);

    return(

        <ReactCardFlip isFlipped={flip} flipDirection="horizontal">
        <Card onClick={ () => { setFlip((prev=> !prev)) }}
            sx={{
                width: "300px",
                height: "350px"
            }}
        >
            <CardActionArea>
                <CardMedia
                    component="img"
                    height="310"
                    image={image}
                    alt="remote-work"
                />
                <CardContent>
                    <Typography gutterBottom variant="h7" component="div">
                        {title}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
        <Card onClick={ () => { setFlip((prev=> !prev)) }}
            sx={{
                width: "300px",
                height: "350px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex"
            }}
        >
            <CardContent>
                <Typography
                    variant="subtitle2"
                >
                    {description}
                </Typography>
            </CardContent>
        </Card>
    </ReactCardFlip>

    )

}


const priceInfromation = () =>{

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);

    return(
        <React.Fragment>
            <InfoIcon     
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            />
            <Popover
                id="mouse-over-popover"
                sx={{
                pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography sx={{ p: 1 }}>Cost of doing business per hour compared to the average price locally</Typography>
            </Popover>
        </React.Fragment>

    )
}
