import { authHeader, serviceAppAuth } from '../_helpers';
import { config } from '../_helpers/apiConfig';
import { handleResponse } from './services.index';
 




export const companyService = {
    getAll,
    findOne,
    getById,
    update,
    delete: _delete
};

async function getAll(serviceApp, filter) {
    let query = filter ? filter : {}
    const requestOptions = {
        method: 'POST',
        headers: serviceApp ? { ...await serviceAppAuth() } : { ...authHeader() },
        body: JSON.stringify(query)
    };

    return fetch(`${config.apiUrl}/database/companies`, requestOptions).then(handleResponse);
}

async function findOne(serviceApp, filter) {
    let query = filter ? filter : {}
    const requestOptions = {
        method: 'POST',
        headers: serviceApp ? { ...await serviceAppAuth() } : { ...authHeader() } ,
        body: JSON.stringify(query)
    };

    return fetch(`${config.apiUrl}/database/companies/findone`, requestOptions).then(handleResponse);
}

async function getById(serviceApp, mongoID) {
    const requestOptions = {
        method: 'GET',
        headers: serviceApp ? { ...await serviceAppAuth() } : { ...authHeader() }
    };

    return fetch(`${config.apiUrl}/database/companies/${mongoID}`, requestOptions).then(handleResponse);
}

async function update(serviceApp, mongoID, data) {
    const requestOptions = {
        method: 'PUT',
        headers: serviceApp ? { ...await serviceAppAuth() } : { ...authHeader() },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiUrl}/database/companies/${mongoID}`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
async function _delete(serviceApp, mongoID) {
    const requestOptions = {
        method: 'DELETE',
        headers: serviceApp ? { ...await serviceAppAuth() } : { ...authHeader() }
    };

    return fetch(`${config.apiUrl}/database/companies/${mongoID}`, requestOptions).then(handleResponse);
}
