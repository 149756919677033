import { authHeader, serviceAppAuth } from '../_helpers';
import { handleResponse } from './services.index';
import { config } from '../_helpers/apiConfig';

export const identityVerificationService = {
    getIdentityVerificationURL,
    validateIdentityVerification,
    searchLuxandPhoto,
    scanMRZPresentID,
    faceVerificationPresentID,
    update,
    findOne,
    findIDV
};

async function faceVerificationPresentID({params, data}){
    const requestOptions = {
        method: 'POST',
        headers: { ...await serviceAppAuth(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    const url = new URL(`${config.apiUrl}/services/identityverification/presentid/face/verify`)
    url.search = new URLSearchParams(params).toString();

    return await fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

async function scanMRZPresentID({params, data}){
    const requestOptions = {
        method: 'POST',
        headers: { ...await serviceAppAuth(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    const url = new URL(`${config.apiUrl}/services/identityverification/presentid/mrz`)
    url.search = new URLSearchParams(params).toString();

    return await fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

async function searchLuxandPhoto({params, data}){
    const requestOptions = {
        method: 'POST',
        headers: { ...await serviceAppAuth(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    const url = new URL(`${config.apiUrl}/services/identityverification/luxand/photo/search`)
    url.search = new URLSearchParams(params).toString();

    return await fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

async function getIdentityVerificationURL({params}){
    const requestOptions = {
        method: 'GET',
        headers: { ...await serviceAppAuth(), 'Content-Type': 'application/json' },
    };

    const url = new URL(`${config.apiUrl}/database/identity/generate/session`)
    url.search = new URLSearchParams(params).toString();

    return await fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

async function validateIdentityVerification({params, data}){
    const requestOptions = {
        method: 'POST',
        headers: { ...await serviceAppAuth(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    const url = new URL(`${config.apiUrl}/database/identity/validate/session`)
    url.search = new URLSearchParams(params).toString();

    return await fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

async function update({params, data}){
    const requestOptions = {
        method: 'POST',
        headers: { ...await serviceAppAuth(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    const url = new URL(`${config.apiUrl}/database/identity/update/${params.mongoID}`)
    url.search = new URLSearchParams(params).toString();

    return await fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

async function findOne({data, params}){
    const requestOptions = {
        method: 'POST',
        headers: { ...await serviceAppAuth(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    const url = new URL(`${config.apiUrl}/database/identity/findone`)
    url.search = new URLSearchParams(params).toString();

    return await fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

async function findIDV({data, params}){
    const requestOptions = {
        method: 'POST',
        headers: { ...await serviceAppAuth(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    const url = new URL(`${config.apiUrl}/database/identity/findidv`)
    url.search = new URLSearchParams(params).toString();

    return await fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

