import { authHeader, serviceAppAuth } from '../_helpers';
import { handleResponse } from './services.index';

 

import { config } from '../_helpers/apiConfig';



export const publicusersService = {
    login,
    create
};


async function login(body, filter) {
    // console.log("Sending Filter Data", query);
    const requestOptions = {
        method: 'POST',
        headers: await serviceAppAuth(),
        body: JSON.stringify(body)
    };
    return fetch(`${config.apiUrl}/database/publicusers/login`, requestOptions).then(handleResponse);
}


async function create(body) {
    // console.log("Sending Filter Data", query);
    const requestOptions = {
        method: 'POST',
        headers: await serviceAppAuth(),
        body: JSON.stringify(body)
    };
    return fetch(`${config.apiUrl}/database/publicusers/create`, requestOptions).then(handleResponse);
}

