import { Card, CardContent, Grid } from "@mui/material"
import React from "react"
import './index.scss'

export default function ServiceLayout(props) {

    return(
        <Grid container direction="row" className='page live-gradient'>
            <Grid
                justifyContent="center"
                alignItems="center"
                className="content"
            >
                <Card
                    style={{
                        height: "80vh",
                        minWidth: "70vw",
                        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                        borderRadius: "8px",
                        display: "flex" 
                    }}
                    className="row justify-content-center align-items-center"
                    variant="outlined"
                >
                    <CardContent>
                        {props.children}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )


}