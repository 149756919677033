import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Breadcrumb = ({ title, rootUrl, parentUrl, currentUrl, subtitle }) => {

    const navigate = useNavigate()

    function handleClick(event) {
        navigate(event)
    }

    return (
        // <div className="breadcrumb-area breadcarumb-style-1 ptb--120">
        //     <div className="container">
        //         <div className="row">
        //             <div className="col-lg-12">
        //                 <div className="breadcrumb-inner text-center">

        <Grid
            container
            display={"flex"}
            direction={"row"}
            textAlign={"center"}
            justifyContent="center"
            alignItems={"center"}
            sx={{
                mt: 10
            }}
        >

            <Grid item xs={12}>
                <Typography variant="h4" className='theme-gradient' dangerouslySetInnerHTML={{ __html: title }}></Typography>
                <Typography variant="h6"  dangerouslySetInnerHTML={{ __html: subtitle }}></Typography>
            </Grid>


            <Grid item>
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            href="/home"
                            onClick={() => { handleClick('/') }}
                        >
                            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            Home
                        </Link>
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            href="/contact"
                            onClick={() => { handleClick('/contact') }}
                        >
                            <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            Contact
                        </Link>

                    </Breadcrumbs>
                </div>
            </Grid>

        </Grid>
    )
}

export default Breadcrumb;