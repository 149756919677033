import { userConstants } from '../_constants';
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';


const initialState = { 
    loggedIn: false, 
    user: {} 
}

const persistConfig = {
    storage, 
    key: "auth", 
    stateReconciler: autoMergeLevel2
  };

export const authentication = persistReducer(persistConfig, 
    (state = initialState, action) => {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                user: action.user
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.user
            };
        case userConstants.LOGIN_REFRESH:
            return{
                loggedIn: true,
                user: action.user
            };
        case userConstants.USER_UPDATE:
            return{
                loggedIn: true,
                user: action.user
            };
        case userConstants.LOGIN_FAILURE:
            return {
                loggingIn: true,
                user: action.user
            };
        case userConstants.LOGOUT:
            return {
                loggingIn: false,
                user: {}
            };
        default:
            return state
    }
})