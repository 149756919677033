import { combineReducers } from 'redux';
import { authentication } from './authentication.reducer';
import { publicuser } from './publicuser.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import {reducer as toastrReducer} from 'react-redux-toastr'
import persistReducer from 'redux-persist/es/persistReducer';
import storage from "redux-persist/lib/storage";




const reducers = combineReducers({
    authentication,
    registration,
    publicuser,
    // user: users,
    alert,
    toastr: toastrReducer
});

const rootReducer = persistReducer({
    key: 'root',
    storage
}, reducers)

export default rootReducer;