
import React, { lazy, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Blog from '../../Pages/Blog/main';
import { CaseStudies } from '../../Pages/Public/CaseStudies/CaseStudiesHome/main';
import Contact from '../../Pages/Public/Contact';
// import { ContactUsForAJob } from '../../Pages/Public/ContactUsForAJob/main';
import PublicSiteHome from '../../Pages/Public/Home';
// import PublicSiteHome from '../../Pages/Public/Home';
// import { RegisterPageCorporateFormik } from '../../Pages/RegisterPage';
// import { ExpiredSession } from '../../Pages/ServicePages/ExpiredSession/main';
import PasswordResetPage from '../../Pages/ServicePages/PasswordReset/main';
import TestComponent from '../../Pages/Test/main';
import LetItRip from '../../Pages/Test/main';
import IDVCamera from '../IdentityVerificationCamera/main';
import Layout from '../_publicsite/common/Layout';
import About from '../_publicsite/elements/about/about';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import PrivacyPolicy from '../../Pages/Policies/Privacy';
import TermsOfService from '../../Pages/Policies/TermsOfService';
import CookiePolicy from '../../Pages/Policies/CookiePolicy';
import AcceptableUse from '../../Pages/Policies/AcceptableUse';
import ScrollToTop from '../../_helpers/scrollToTop';
import CookieConsent from '../_publicsite/elements/cookie/main';


const Root = () => {

    const Corporate = lazy(() =>
        import('./Lazy/corporate/mainRoutes')
    )

    const WorkSpace = lazy(() =>
        import('./Lazy/workspace/mainRoutes')
    )

    return (
        <Routes>
            {/**Corporate Routes */}
            <Route
                path="corporate/*"
                element={
                    <React.Suspense fallback={<div>Hey taking my time... </div>}>
                        <Corporate />
                    </React.Suspense>
                }
            />
            {/**Workspace Routes */}
            <Route
                path="workspace/*"
                element={
                    <React.Suspense fallback={<div>Hey taking my time... </div>}>
                        <WorkSpace />
                    </React.Suspense>
                }
            />
            {/**Register Page Universal*/}
            {/* <Route path="/register/:companyID?" element={<RegisterPageCorporateFormik/>} /> */}
            {/**Public Pages*/}
            {/* <Route exact path="/reset/password/:tempKey?/:companyID?"    element={<PasswordResetPage/>} />
                <Route exact path="/identity/:sessionID?"                    element={<IDVCamera/>} />
                <Route exact path="/expired"                                 element={<ExpiredSession/>} />
                <Route exact path="/join-us/contact-form"                    element={<ContactUsForAJob/>} /> */}
            <Route element={
                <>
                    <CookieConsent/>
                    <ScrollToTop />
                    <Layout />
                </>
            }>

                <Route index element={<PublicSiteHome />} />
                <Route exact path="contact" element={<Contact />} />
                <Route exact path="case-study/:id" element={<CaseStudies />} />
                <Route exact path="/reset/password/:tempKey?/:companyID?" element={<PasswordResetPage />} />
                {/* <Route exact path="/blog/:id?" element={<Blog />} /> */}
                <Route exact path="/linkedin" element={<LinkedInCallback />} />
                <Route exact path="/policies/privacy" element={<PrivacyPolicy />} />
                <Route exact path="/policies/terms" element={<TermsOfService />} />
                <Route exact path="/policies/cookiepolicy" element={<CookiePolicy />} />
                <Route exact path="/policies/acceptable-use-policy" element={<AcceptableUse />} />

            </Route>


            {/** Tester Route */}
            {/* <Route exact path="/test"                                 element={TestComponent} /> */}
            {/**Fall Back Path */}
            <Route path="/" element={<Navigate to="/" replace />} />
            <Route path="*" element={<Navigate to="/" replace />} />


        </Routes>
    )
}


export default Root