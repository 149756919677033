import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HomeIcon from '@mui/icons-material/Home';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import HikingIcon from '@mui/icons-material/Hiking';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';

function createData(name, RegularJob, GigLife) {
  return { name, RegularJob, GigLife };
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const rows = [
  createData({text: 'Work From Home', icon: <HomeIcon/>}, {text: 'No', icon:  <SentimentVeryDissatisfiedIcon color="error"/>}, {text: 'Permanent', icon:  <TagFacesIcon color="success"/>}),
  createData({text: 'Flexible Schedules', icon: <AccessTimeIcon/>}, {text: 'Maybe', icon: <SentimentVeryDissatisfiedIcon color="error"/> }, {text: 'That\'s us!', icon: <TagFacesIcon color="success"/>}),
  createData({text: 'Traffic', icon: <EmojiTransportationIcon/>}, {text: 'Maybe', icon: <SentimentVeryDissatisfiedIcon color="error"/> }, {text: 'Lol', icon: <TagFacesIcon color="success"/>}),
  createData({text: 'Work-life Balance', icon: <VolunteerActivismIcon/>}, {text: 'Maybe', icon: <SentimentVeryDissatisfiedIcon color="error"/> }, {text: 'Yes', icon: <TagFacesIcon color="success"/>}),
  createData({text: 'Growth', icon: <HikingIcon/>}, {text: 'Yes', icon: <TagFacesIcon color="success"/> }, {text: 'Yes', icon: <TagFacesIcon color="success"/>}),
];
const rowsUnfortunates = [
  createData({text: 'Government Health Insurance', icon: <LocalHospitalIcon/>}, {text: 'Some', icon:  <TagFacesIcon color="success"/>}, {text: 'No', icon:  <SentimentVeryDissatisfiedIcon color="error"/>}),
  createData({text: 'Bono 14 & Aguinaldo', icon: <AttachMoneyIcon/>}, {text: 'Yes', icon: <TagFacesIcon color="success"/> }, {text: 'Included in base pay', icon: <TagFacesIcon color="success"/>}),
  createData({text: 'Vacations', icon: <DateRangeIcon/>}, {text: 'Yes', icon: <TagFacesIcon color="success"/> }, {text: 'You choose your hours & time-off', icon: <TagFacesIcon color="success"/>}),
];

export default function GigLifeComparison() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{fontSize: "18px"}}>What's in it for me!</StyledTableCell>
            <StyledTableCell align="right">Regular 7AM - 5PM (if lucky)</StyledTableCell>
            <StyledTableCell align="right">#GigLife</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow
              key={row.name.text}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <StyledTableCell component="th" scope="row">
                {row.name.text} {row.name.icon} 
              </StyledTableCell>
              <StyledTableCell align="right">{row.RegularJob.text} {row.RegularJob.icon}</StyledTableCell>
              <StyledTableCell align="right">{row.GigLife.text} {row.GigLife.icon}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{fontSize: "18px"}}>Included Benefits</StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsUnfortunates.map((row) => (
            <StyledTableRow
              key={row.name.text}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <StyledTableCell component="th" scope="row">
                {row.name.text} {row.name.icon} 
              </StyledTableCell>
              <StyledTableCell align="right">{row.RegularJob.text} {row.RegularJob.icon}</StyledTableCell>
              <StyledTableCell align="right">{row.GigLife.text} {row.GigLife.icon}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
