import { Box, Grid, Typography } from "@mui/material"
import { Container } from "@mui/system"
import React from "react"
import EnterBlog from "./EnterNewBlog/EnterBlog"
import DOMPurify from 'dompurify';
import { useDispatch, useSelector } from "react-redux";
import BlogFeed from "./Feed/BlogFeed";
import { publicUserActions } from "../../_redux/_actions/publicuser.actions";
import { publicSocket } from "../../_services/services.index";



const Blog = () => {
    const publicuser                              = useSelector(state => state.publicuser?.user);
    const [convertedContent, setConvertedContent] = React.useState(null);
    const [addingNewPost, setAddingNewPost]       = React.useState(false);
    const [blogPosts, setBlogPosts]               = React.useState([]);
    const dispatch                                = useDispatch();

    const createMarkup = (html) => {
        return {
          __html: DOMPurify.sanitize(html)
        }
    }

    React.useEffect(() => {
        if(addingNewPost === false){
            setConvertedContent(null);
        }
    },[addingNewPost]);

    React.useEffect(() =>{
        publicSocket.emit("watchpublicuser", { _id: publicuser?._id });
    },[])
    
    React.useEffect(() => {

        function onPublicUser(response) {
            // console.log(response)
            const updatedUser = Object.assign({}, publicuser, response.data);
            dispatch(publicUserActions.refresh(updatedUser));

        }

        function onDisconnect() {
            // console.log("Disconnected, reconnecting", publicuser)
            publicSocket.emit("watchpublicuser", { _id: publicuser?._id });
            publicSocket.emit("watchblogposts", {_id: publicuser?._id});

        }

        function onPublicUserLogout(){
            publicSocket.off('publicuser', onPublicUser);
        }

        publicSocket.on('publicuser', onPublicUser);
        publicSocket.on('publicuserlogout', onPublicUserLogout);
        publicSocket.on('disconnect', onDisconnect);

        return () => {
            publicSocket.off('disonnect', onDisconnect);
            publicSocket.off('publicuser', onPublicUser);
        }

    }, []);


    return(
        <Box sx={{ flexBasis: 1, width: "100%", mt: 10, mb: 20}}>
            <Grid
                container
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                spacing={4}
            >   
                
                {publicuser && addingNewPost &&
                <Grid item>
                    { convertedContent ?
                    <Box
                        className="preview"
                        dangerouslySetInnerHTML={createMarkup(convertedContent)}
                        sx={{
                            height: "500px",
                            width: "60vw",
                            overflow: "scroll"
                        }}
                    />
                    :
                    <Typography>Content will appear here when editting the box</Typography>
                    }
                </Grid>
                }

                {!addingNewPost &&
                <Grid item>
                    <BlogFeed setBlogPosts={setBlogPosts} blogPosts={blogPosts}/>
                </Grid>
                }

                <Grid 
                    item
                    xs={12}
                >
                    <EnterBlog 
                        setConvertedContent={setConvertedContent} 
                        setAddingNewPost={setAddingNewPost} 
                        addingNewPost={addingNewPost}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default Blog