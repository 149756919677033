import React from 'react';
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import {Link} from "react-router-dom";
import PropTypes from 'prop-types';
// import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import GigLifeComparison from '../tables/gigLifeComparison/GigLifeComparison';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import CompareYourIncome from '../charts/compareYourIncome';
import { styled } from '@mui/material/styles';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

const TabOne = () => {

    const theme = useTheme();
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const handleChangeIndex = (index) => {
      setValue(index);
    };

    const Item = styled(Box)(({ theme }) => ({
        // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: "#ffffff",
        height: "500px"
      }));

    return (

        <Box sx={{ bgcolor: 'background.paper', height: "500px", width: "80vw" }}>
        <AppBar position="static" sx={{ bgcolor:"transparent", }}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            color="secondary.contrastText"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab onMouseDown={(e) => {e.preventDefault()}} label="The #GigLife" {...a11yProps(0)} icon={<Diversity1Icon/>} />
            <Tab onMouseDown={(e) => {e.preventDefault()}} label="Compare your income" {...a11yProps(1)} icon={<PriceCheckIcon/>} />
            {/* <Tab  onMouseDown={(e) => {e.preventDefault()}} label="Item Three" {...a11yProps(2)} /> */}
          </Tabs>
        </AppBar>
        {/* <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel  value={value} index={0} dir={theme.direction}>
            <GigLifeComparison/>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
              <Item>
                <CompareYourIncome theme={theme}/>
              </Item>
          </TabPanel>
          {/* <TabPanel value={value} index={2} dir={theme.direction}>
            Item Three
          </TabPanel>
        </SwipeableViews> */}
      </Box>

        /*
        <div>
            <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row row--30 align-items-center">
                                <div className="col-lg-12">
                                    <div className="rn-default-tab style-three">
                                        <div className="tab-button-panel">
                                            <TabList className="tab-button">
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Stategy</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Development</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Reporting</button>
                                                    </div>   
                                                </Tab>
                                            </TabList>
                                        </div>

                                        <div className="tab-content-panel">
                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <div className="row align-items-center row--30">
                                                            <div className="col-lg-4">
                                                                <div className="section-title">
                                                                    <h4 className="title">How can we help you?</h4>
                                                                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero ea sint nihil corrupti! Harum nemo eius odio.</p>
                                                                    <div className="read-more">
                                                                        <a className="btn-default btn-small" href="#">Read More</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 mt_md--30 mt_sm--30">
                                                                <div className="row row--15 mt_dec--30 service-wrapper">
                                                                    {dataList.map( (val , i) => (
                                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                                                            <div className="card-box card-style-1 text-start">
                                                                                <div className="inner">
                                                                                    <div className="image">
                                                                                        <Link to="#service">
                                                                                            <img src={`${val.image}`} alt="card Images" />
                                                                                        </Link>
                                                                                    </div>
                                                                                    <div className="content">
                                                                                        <h4 className="title mb--20"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                                                                        <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                                                                        <Link className="btn-default btn-small" to="#service">Read More</Link>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            
                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <div className="row align-items-center row--30">
                                                            <div className="col-lg-4">
                                                                <div className="section-title">
                                                                    <h4 className="title">Whice Service Provided?</h4>
                                                                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero ea sint nihil corrupti! Harum nemo eius odio.</p>
                                                                    <div className="read-more">
                                                                        <a className="btn-default btn-small" href="#">Read More</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 mt_md--30 mt_sm--30">
                                                                <div className="row row--15 mt_dec--30 service-wrapper">
                                                                    {dataList2.map( (val , i) => (
                                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                                                            <div className="card-box card-style-1 text-start">
                                                                                <div className="inner">
                                                                                    <div className="image">
                                                                                        <Link to="#service">
                                                                                            <img src={`${val.image}`} alt="card Images" />
                                                                                        </Link>
                                                                                    </div>
                                                                                    <div className="content">
                                                                                        <h4 className="title mb--20"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                                                                        <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                                                                        <Link className="btn-default btn-small" to="#service">Read More</Link>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <div className="row align-items-center row--30">
                                                            <div className="col-lg-12">
                                                                <div className="row row--15 mt_dec--30 service-wrapper">
                                                                    {dataList3.map( (val , i) => (
                                                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                                                            <div className="card-box card-style-1 text-start">
                                                                                <div className="inner">
                                                                                    <div className="image">
                                                                                        <Link to="#service">
                                                                                            <img src={`${val.image}`} alt="card Images" />
                                                                                        </Link>
                                                                                    </div>
                                                                                    <div className="content">
                                                                                        <h4 className="title mb--20"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                                                                        <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                                                                        <Link className="btn-default btn-small" to="#service">Read More</Link>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
        </div>

        */
    )
}

export default TabOne
