import { Avatar, Card, CardContent, Divider, Grid, Paper, Typography } from "@mui/material";
import { borderRadius, Box, Container, fontSize, Stack } from "@mui/system";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import SEO from "../../../../_components/_publicsite/common/SEO";
import { useTheme } from '@mui/material/styles';
import leadgen from '../../media/casestudies/lead-generation.jpeg'
import healthprogram from '../../media/casestudies/health-program.jpeg'
import { Donut, CircleGrid, Diamond, Circle } from "react-awesome-shapes";
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import "./index.scss";
import zIndex from "@mui/material/styles/zIndex";

export const solutions = [
    {
        StudyID: 1,
        icon: FilterAltIcon,
        projectTitle: "Lead Generation - Remote Work",
        image: leadgen,
        overview: "With a list of 400,000 Leads & only 5 approved resources, we required to provide at least 5 leads per week that would feel intrested in a product",
        year: "2021",
        type: "Lead Generator",
        quote: "\"It's amazing how leveraging Technology we were able to reduce time & effort in vetting un-wanted contacts\"",
        name: "CEO For Lead Generation Program",
        solution: `
        <p>
        Through the help of our Sister Company Reporting Pal we implemented an \"Operational\" server that
        will hand-off & process all calls that were completed from our Champions.
        </p>
        <p>
        This server would not only take care of analyzing through Sentiment Scores which contacts are good, but,
        would also take care of "\pre-qualifying\" prospects through a Q&A driven by the Champion which would programatically
        determine a pass or fail.
        </p>
        `,
        results: [
            {
                result: "+ $500,000 USD",
                text: "Total revenue generated for Inc 8000 companies"
            },
            {
                result: "+ 300,000 contacts",
                text: "Contacted from owned & retrieved potential Candidates"
            },
            {
                result: "+ 5 leads",
                text: "Generated per week"
            },
            {
                result: "+ 400,000 contacts",
                text: "Analyzed in under 10 minutes with our \"Cook Book\""
            }
        ],
        benefits: [
            {
                title: "Reduced Provider's Costs",
                description: "Due to our \"Cook Book\" we were able to analyze contacts within a Trial Period which allowed us to discard un-wanted prospects."
            },
            {
                title: "Instant Leads Sent via API",
                description: "Upon finding interest, our Champions wouldn't require to explain the vetted prospect, instead, account history & information was sent via API to Third Party Provider."
            }
        ]
    },


    {
        StudyID: 2,
        icon: LocalHospitalIcon,
        projectTitle: "Near Shore Health Program Launch",
        image: healthprogram,
        overview: "To achieve a cost reduction of $10.00 USD per hour per agent, we had to relocate a program operating in the United States to one of our largest nearshore solutions in Guatemala. The program had over 50 contractors. ",
        year: "2022",
        type: "BPO Customer Service",
        quote: "\"You are the example to all of our other established Vendor relationships\"",
        name: "Vendor Site Lead for Health Company",
        solution: `
        <p>
        We go beyond simply providing a service to our clients by leveraging our extensive BPO knowledge. Our team is dedicated to identifying, planning, and executing any improvements that can be made in our operations.        </p>
        <p>
        Due to Guatemala's status as a BPO hub for some of the world's most reputable providers, we have a vast pool of candidates with extensive experience and the ability to adapt to businesses in any industry with ease.        </p>
        <p>
        One of our three client programs was focused on providing customer service for healthcare. Despite the fact that all three programs were launched within a six-month period, we were able to maintain stability in our delivery times by utilizing appropriate staffing reports, high-volume sourcing through biometrics, custom applications, and internal automated workflows. Our commitment to a Six Sigma culture also ensured continuous feedback and improvement in our processes.        </p>
        <p>
        All this has led to public acknowledgment of being an example to other vendors.
        </p>
        `,
        results: [
            {
                result: "+ $38,000 USD",
                text: "Total savings per month"
            },
            {
                result: "75% vs. 45%",
                text: "Graduation Rate"
            },
            {
                result: "From 86% to 99%",
                text: "Hours Delivery in less than 6 months"
            },
        ],
        benefits: [
            {
                title: "Actual Staffing Compliance",
                description: "Before the program started, there was no Requirments to Staffing per Interval. With our extensive BPO knoweldge (at no additional cost) a Staffing assumption corrected the deltas based on Historical Analysis hitting a 99% & over mark."
            },
            {
                title: "Knowledge Base & Process Optimization",
                description: "Through our Support Contractors, Identified areas of opportunity for our Clients Vendor Partners was consistently shared & highlighted in order to \"re-image\" some of the bottle necks in the workflows"
            },
            {
                title: "Biometric Check & Identity Compliance",
                description: "Through our Sister Company -Reporting Pal- an Identity, Biometric & Phone Security check allowed on-boarding \"real\" & honest Contractors."
            }
        ]
    }
]



const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);


export const CaseStudies = () => {

    const [selected, setSelected] = React.useState(null);
    const [lightLogo, setLightLogo] = React.useState("https://rfcapp.blob.core.windows.net/rfc-public/logo/png/icon_trimmed_transparent_color1.png?sp=r&st=2022-11-02T03:36:12Z&se=2032-11-02T11:36:12Z&spr=https&sv=2021-06-08&sr=b&sig=9xvXQrp33vNy%2BeavHmVbOZSTo4ObNn4nMkCrQaZW0Iw%3D");
    const [darkLogo, setDarkLogo] = React.useState("https://rfcapp.blob.core.windows.net/rfc-public/logo/png/icon_trimmed_transparent_white.png?sp=r&st=2022-11-02T03:35:17Z&se=2032-11-02T11:35:17Z&spr=https&sv=2021-06-08&sr=b&sig=zfZL80iwALjHXponyRO%2FNz3u3Oxh2FDkJCQ5Uv4x7U8%3D");
    const params = useParams();
    const theme = useTheme();
    const navigate = useNavigate();

    React.useEffect(() => {
        // console.log(params.id)
        const find = solutions.find((v) => parseInt(v.StudyID) === parseInt(params.id));
        if(find){
            setSelected(find);
        }else if(!find){
            navigate("/")
        }
    }, [params.id])


    return (
        <React.Fragment>
            <SEO title="Remote Freedom Connect BPO" />
            <Box sx={{ color: 'white', pt: "50px", pb: "100px" }}>
                 <Donut
                    color={theme.palette.primary.light}
                    size="180px"
                    width={['40px', '40px', '60px', '60px']}
                    zIndex={0}
                    top="-30px"

                />
                <div
                    style={{
                        transform: "rotate(20deg)",
                        display: "flex",
                        position: "absolute",
                        right: "350px",
                        top: "20px",
                        zIndex: 0
                    }}
                >
                    <CircleGrid
                        className="rotate"
                        color={theme.palette.secondary.main}
                        size="175px"
                        zIndex={0}

                    />

                </div>

                {/* {JSON.stringify(params.id)} */}
                <Container
                    sx={{
                        zIndex: 10,
                        height: "100%"
                    }}
                >
                    {/**GRID ROW */}
                    <Grid
                        sx={{ flexGrow: 1, zIndex: 1 }}
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={3}
                    >

                        {/**Card with Overview */}
                        <Grid
                            item
                            lg={4}
                            sx={{
                                zIndex: 1
                            }}
                        >
                            <Card
                                sx={{
                                    height: "1200px",
                                    borderRadius: 50,
                                    boxShadow: 3,
                                    zIndex: 1 
                                }}

                            >
                                <CardContent>
                                    <Container>
                                        <Grid
                                            container
                                        >
                                            <Grid
                                                item
                                                container
                                                sx={{
                                                    mt: 3,
                                                    mb: 5
                                                }}
                                                display={"flex"}
                                                justifyContent="center"
                                            >
                                                <Avatar
                                                    alt={`case-${selected?.type}`}
                                                    src={selected?.image}
                                                    sx={{
                                                        width: 200,
                                                        height: 200,
                                                        zIndex: 2
                                                    }}
                                                />
                                            </Grid>
                                            <Stack
                                                spacing={1}
                                            >
                                                <Typography
                                                    variant="h7"
                                                    component={"span"}
                                                    color="secondary.contrastText"
                                                    sx={{
                                                        textTransform: "uppercase"
                                                    }}
                                                >
                                                    Project Name:
                                                </Typography>

                                                <Typography
                                                    variant="subtitle1"
                                                    component="span"
                                                    color="grey.500"
                                                >
                                                    {selected?.projectTitle}
                                                </Typography>

                                                <Divider />
                                            </Stack>

                                            <Stack
                                                spacing={1}
                                                sx={{ mt: 2 }}
                                            >
                                                <Typography
                                                    variant="h7"
                                                    component={"span"}
                                                    color="secondary.contrastText"
                                                    sx={{
                                                        textTransform: "uppercase"
                                                    }}
                                                >
                                                    Client Name:
                                                </Typography>

                                                <Typography
                                                    variant="subtitle1"
                                                    component="span"
                                                    color="grey.500"
                                                >
                                                    {selected?.name}
                                                </Typography>

                                                <Divider />
                                            </Stack>

                                            <Stack
                                                spacing={1}
                                                sx={{ mt: 2 }}
                                            >
                                                <Typography
                                                    variant="h7"
                                                    component={"span"}
                                                    color="secondary.contrastText"
                                                    sx={{
                                                        textTransform: "uppercase"
                                                    }}
                                                >
                                                    Overview
                                                </Typography>

                                                <Typography
                                                    variant="subtitle2"
                                                    component="span"
                                                    color="grey.500"
                                                >
                                                    {selected?.overview}
                                                </Typography>

                                                <Divider
                                                    sx={{
                                                        width: "20%"
                                                    }}
                                                />
                                            </Stack>



                                            <Stack
                                                spacing={1}
                                                sx={{ mt: 2 }}
                                            >
                                                <Typography
                                                    variant="subtitle1"
                                                    component={"span"}
                                                    color="secondary.contrastText"
                                                    dangerouslySetInnerHTML={{ __html: selected?.quote }}
                                                    sx={{
                                                        fontStyle: "italic"
                                                    }}
                                                />
                                            </Stack>

                                            <Stack
                                                spacing={2}
                                                sx={{ mt: 5, width: "100%" }}

                                            >
                                                {selected?.results.length > 0 &&
                                                    selected?.results.map((v, index) =>
                                                        <Paper
                                                            key={index}
                                                            elevation={3}
                                                            sx={{
                                                                borderRadius: "20px",
                                                                height: "80px"
                                                            }}
                                                        >
                                                            <Box
                                                                alignItems="center"
                                                                display={"flex"}
                                                                sx={{
                                                                    height: "80px"
                                                                }}
                                                            >
                                                                <Container>
                                                                    <Grid
                                                                        container
                                                                        direction={"row"}
                                                                        alignItems="center"
                                                                        display={"flex"}
                                                                    >
                                                                        <Grid
                                                                            item
                                                                            xs={7}
                                                                            sx={{
                                                                                color: "primary.light"
                                                                            }}
                                                                        >
                                                                            <Typography variant="subtitle2">
                                                                                {v.result}
                                                                            </Typography>

                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={5}
                                                                            sx={{
                                                                                color: "secondary.contrastText",
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                sx={{
                                                                                    fontSize: "10px"
                                                                                }}
                                                                            >
                                                                                {v.text}
                                                                            </Typography>

                                                                        </Grid>

                                                                    </Grid>
                                                                </Container>

                                                            </Box>
                                                        </Paper>
                                                    )
                                                }
                                            </Stack>
                                        </Grid>
                                    </Container>


                                </CardContent>
                            </Card>
                        </Grid>

                        {/**Case Study Content */}
                        <Grid 
                            item 
                            lg={8}
                        >
                            <Grid
                                container
                                spacing={2}
                            >
                                {/** Brand */}
                                <Grid
                                    item
                                    container
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            zIndex: 2
                                        }}
                                    >
                                        <Stack
                                            direction="column"
                                            spacing={3}
                                            alignItems="center"
                                            display={"flex"}
                                            textAlign={"center"}
                                            alignContent="center"
                                            justifyContent={"center"}
                                        >
                                            <img
                                                style={{
                                                    height: '50px',
                                                    width: 'auto'
                                                }}
                                                src={theme.palette.mode === 'dark' ? darkLogo : lightLogo}
                                            />
                                            <Typography component="span" variant="h4" className="theme-gradient" >Remote Freedom Connect</Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sx={{ mt: 2 }}>
                                    <Typography component="span" variant="h3" color="secondary.contrastText" >
                                        Case Study
                                    </Typography>
                                    <br />
                                    <Typography component="span" variant="h5" color="primary" >
                                        {selected?.type} {selected?.year}
                                    </Typography>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Divider
                                        sx={{
                                            width: "10%",
                                            borderBottomWidth: 5
                                        }}
                                    >

                                    </Divider>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        spacing={2}
                                    >
                                        <Grid
                                            item
                                            xs={5}
                                            container
                                        >
                                            <Grid
                                                item
                                            >
                                                <Typography variant="h5" color="secondary.contrastText">
                                                    The Solution
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                container
                                                display={"flex"}
                                                // alignContent="center"
                                                sx={{
                                                    height: "100%",
                                                    width: "100%"
                                                }}
                                            >
                                                <Typography
                                                    sx={{ mt: 2 }}
                                                    component="div"
                                                    variant="subtitle2"
                                                    color="grey.500"
                                                    dangerouslySetInnerHTML={{ __html: selected?.solution }}
                                                />
                                            </Grid>

                                        </Grid>
                                        {/**Vertical Divider */}
                                        <Grid
                                            item
                                            xs={1}
                                        >
                                            <Divider
                                                orientation="vertical"
                                            >

                                            </Divider>
                                        </Grid>
                                        {/**Vertical Divider */}
                                        <Grid
                                            item
                                            xs={5}
                                            container
                                            spacing={2}
                                        >

                                            <Grid
                                                item
                                            >
                                                <Typography variant="h5" color="secondary.contrastText">
                                                    The Benefits
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                            >
                                                <Stack
                                                    direction="column"
                                                    spacing={3}
                                                    display={"flex"}
                                                    // justifyContent={"center"}
                                                    sx={{
                                                        height: "100%",
                                                        width: "100%"
                                                    }}
                                                >
                                                    {selected?.benefits.length > 0 &&
                                                        selected?.benefits.map((ben, index) =>
                                                            <React.Fragment key={index}>
                                                                <Typography
                                                                    component="span"
                                                                    variant="h5"
                                                                    color="secondary.contrastText"
                                                                >
                                                                    {bull} {ben.title}
                                                                </Typography>

                                                                <Typography
                                                                    component="div"
                                                                    variant="subtitle2"
                                                                    color="grey.500"
                                                                    dangerouslySetInnerHTML={{ __html: ben.description }}
                                                                />
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </Stack>

                                                {/* <Typography
                                                    sx={{ mt: 2 }}
                                                    component="div"
                                                    variant="subtitle2"
                                                    color="grey.500"
                                                    dangerouslySetInnerHTML={{ __html: selected?.solution }}
                                                /> */}
                                            </Grid>


                                        </Grid>
                                    </Grid>
                                </Grid>



                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
                <div
                        className="bounce2"
                        style={{
                            zIndex: 0,
                            display: "flex",
                            bottom: "-180px",
                            left: "125px",
                            position: "absolute"
                        }}
                    >
                        <Diamond
                            color={theme.palette.primary.light}
                            width={['40px', '40px', '60px', '60px']}
                            size="100px"
                            zIndex={0}
                        />
                    </div>                    
                    <Circle
                        className=""
                        color={theme.palette.secondary.light}
                        width={['40px', '40px', '60px', '60px']}
                        size="100px"
                        bottom={"-120px"}
                        right={"150px"}
                        zIndex={0}
                    />
            </Box>
        </React.Fragment>

    )
}



export const ListOfCaseStudies = () => {



    return (
        <Grid
            container
        >

        </Grid>
    )
}