import { authHeader, serviceAppAuth } from '../_helpers';
import { handleResponse } from './services.index';

 

import { config } from '../_helpers/apiConfig';



export const linkedinsService = {
    fetchUserInfo
};


async function fetchUserInfo(body) {
    // console.log("Sending req", body);
    const requestOptions = {
        method: 'POST',
        headers: await serviceAppAuth(),
        body: JSON.stringify(body)
    };
    return fetch(`${config.apiUrl}/services/linkedin/userinfo`, requestOptions).then(handleResponse);
}

