import React from 'react'


export const handleClickShowPassword = (setShowPassword, showPassword) => {
  setShowPassword(!showPassword);
};

export const handleMouseDownPassword = (event) => {
  event.preventDefault();
};

export const setPhone = (value, country, formik) => {
  formik.setFieldValue('country', country?.name);
  formik.setFieldValue('short', country?.countryCode?.toUpperCase());
  formik.setFieldValue('countryCode', country?.dialCode);
  formik.setFieldValue('phoneNumber', value);
  formik.setFieldValue('phone', value);
}