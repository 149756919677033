import React from "react"
import ServiceLayout from "../../../_layouts/Services/ServiceLayout"
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Card, CardContent, CardMedia, Grid, TextField } from '@mui/material';
import { companyService } from "../../../_services/company.services";
import { filesService } from "../../../_services/files.service";
import { generalService } from "../../../_services/general.services";
import { serviceRequestsService } from "../../../_services/servicerequests.service";
import moment from "moment-timezone";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LockClockIcon from '@mui/icons-material/LockClock';
import { useParams } from "react-router-dom";


const validationSchema = Yup.object({
    password: Yup.string()
        .required('Please Enter your password')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character."
        ),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match').required('Please confirm your password'),
});


const PasswordResetPage = (props) => {

    const params = useParams();

    const tempKey   = params.tempKey
    const companyID =  params.companyID

    const [completed, setCompleted] = React.useState(false);
    const [expired, setExpired] = React.useState(false);
    const [image, setImage] = React.useState(null);

    React.useEffect(() => {
        fetchLogo();
        fetchResetRequest();
    }, []);

    // Fetch the tempKey data.
    const fetchResetRequest = React.useCallback(async() => {
        const findServiceRequest = await serviceRequestsService.findOne(true, {tempKey});
        // console.log(findServiceRequest)
        if(findServiceRequest?.data?.completed === true){
            setCompleted(true);
        }
        if(moment().unix() > findServiceRequest.expirationDateTime){
            setExpired(true);
        }
    }, [completed, expired]);

    const fetchLogo = React.useCallback(async () => {
        // console.log("Company ID", props.match)
        const findCompany = await companyService.findOne(true, { id: companyID });
        if (findCompany.status === 200) {
            const findFile = await filesService.findOne(true, { id: findCompany.data.companyLogoID });
            // console.log(findFile)
            if (findFile.status === 200) {
                const imageURL = await generalService.getBlobSasUriPublic(true, findFile.data);
                // console.log(imageURL)
                setImage(imageURL.data)
            }
        }
    }, [companyID]);


    // Update the new Password & Save the FetchKey data.
    const sendNewPassword = React.useCallback(async (values) => {
        // console.log(values);
        const resetPasswordData = {
            ...values,
            tempKey
        }

        console.log("Resetting Password");
        const resetPasswordRequest = await generalService.resetPassword(true, resetPasswordData);
        if(resetPasswordRequest.status !== 200){

        }else{
            setCompleted(true);
        }
    }, []);


    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            sendNewPassword(values);
        },
    });

    // Submit Handler
    const submit = () => {
        formik.handleSubmit();
    };


    return (
        <ServiceLayout>
            {(completed || expired) &&
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                {completed &&
                    <CheckCircleOutlineIcon color="success" sx={{ fontSize: 200 }}/>
                }

                {(!completed && expired) &&
                    <LockClockIcon color="warning" sx={{ fontSize: 200 }}/>
                }

                {completed ? 'This session has been completed' : (expired && !completed) ? 'This session has expired' : ''}



              </Grid>
            }

            {image?.url && completed === false && expired === false &&
                <Card
                    style={{
                        height: "30vh",
                        minWidth: "15vw",
                        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                        borderRadius: "8px",
                        display: "flex"
                    }}
                    className="row justify-content-center align-items-center"
                >
                    <CardMedia
                        component="img"
                        sx={{
                            height: '75px',
                            width: 'auto',
                            padding: '8px'
                        }}
                        image={image?.url}
                    />
                    <CardContent>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                                columns={1}
                                maxWidth={"15vw"}
                            >
                                <Grid xs={1} item key={1}>
                                    <TextField
                                        fullWidth
                                        id="password"
                                        name="password"
                                        label="Password"
                                        type="password"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        error={formik.touched.password && Boolean(formik.errors.password)}
                                        helperText={formik.touched.password && formik.errors.password}
                                    />
                                </Grid>
                                <Grid xs={1} item key={2}>
                                    <TextField
                                        fullWidth
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        label="Confirm Password"
                                        type="password"
                                        value={formik.values.confirmPassword}
                                        onChange={formik.handleChange}
                                        error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                    />
                                </Grid>
                                <Grid xs={1} item style={{}}>
                                    <Button
                                        onClick={submit}
                                        color="primary"
                                        variant="contained"
                                        fullWidth
                                    >
                                        Update New Password
                                    </Button>
                                </Grid>

                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            }
        </ServiceLayout>
    )
}

export default PasswordResetPage