import { authHeader, serviceAppAuth } from '../_helpers';
import { handleResponse } from './services.index';
 

import { config } from '../_helpers/apiConfig';
 


export const generalService = {
    getBlobSasUri,
    getBlobSasUriPublic,
    resetPasswordRequest,
    resetPassword,
    joinUsMailingList,
    getInTouch,
    identityVerificationApproval,
    resetIdentityVerification
};

async function getBlobSasUri(serviceApp, data) {
    const requestOptions = {
        method: 'POST',
        headers: serviceApp ? await serviceAppAuth() : authHeader(),
        body: JSON.stringify(data)
    };
    return await fetch(`${config.apiUrl}/general/storage/generateblobsasuri`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

async function getBlobSasUriPublic(serviceApp, data) {
    const requestOptions = {
        method: 'POST',
        headers: serviceApp ? await serviceAppAuth() : authHeader(),
        body: JSON.stringify(data)
    };
    return await fetch(`${config.apiUrl}/general/storage/generateblobsasuri`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

async function resetPasswordRequest(serviceApp, data) {
    const requestOptions = {
        method: 'POST',
        headers: serviceApp ? await serviceAppAuth() : authHeader(),
        body: JSON.stringify(data)
    };
    return await fetch(`${config.apiUrl}/general/reset/password/request`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

async function resetPassword(serviceApp, data) {
    const requestOptions = {
        method: 'POST',
        headers: serviceApp ? await serviceAppAuth() : authHeader(),
        body: JSON.stringify(data)
    };
    return await fetch(`${config.apiUrl}/general/reset/password`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

async function identityVerificationApproval(serviceApp, data) {
    const requestOptions = {
        method: 'POST',
        headers: serviceApp ? await serviceAppAuth() : authHeader(),
        body: JSON.stringify(data)
    };
    return await fetch(`${config.apiUrl}/general/approve/identityverification`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
    });
}

async function resetIdentityVerification(serviceApp, data) {
    const requestOptions = {
        method: 'POST',
        headers: serviceApp ? await serviceAppAuth() : authHeader(),
        body: JSON.stringify(data)
    };
    return await fetch(`${config.apiUrl}/general/reset/identityverification`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
    });
}


async function joinUsMailingList(serviceApp, data) {
    const requestOptions = {
        method: 'POST',
        headers: serviceApp ? await serviceAppAuth() : authHeader(),
        body: JSON.stringify(data)
    };
    return await fetch(`${config.apiUrl}/general/join-us/contact`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

async function getInTouch(serviceApp, data) {
    const requestOptions = {
        method: 'POST',
        headers: serviceApp ? await serviceAppAuth() : authHeader(),
        body: JSON.stringify(data)
    };
    return await fetch(`${config.apiUrl}/general/sales/contact`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}