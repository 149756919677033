import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { switchColorMode } from '../../../../App/App';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useScrollTrigger } from '@mui/material';
import { borderBottom } from '@mui/system';
import { solutions } from "../../../../../src/Pages/Public/CaseStudies/CaseStudiesHome/main"
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

const pages = [
  { name: 'Home', route: '/' },
  // { name: 'About', route: '/about' },
  { name: 'Contact', route: '/contact' },
  // { name: 'Corp Login', route: '/corporate/login' },
  // { name: '#GetGigAlerts', route: '/join-us/contact-form' }
];

const Header = ({ btnStyle, HeaderSTyle }) => {

  const { toggleColorMode } = switchColorMode();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [lightLogo, setLightLogo] = React.useState("https://rfcapp.blob.core.windows.net/rfc-public/logo/png/icon_trimmed_transparent_color1.png?sp=r&st=2022-11-02T03:36:12Z&se=2032-11-02T11:36:12Z&spr=https&sv=2021-06-08&sr=b&sig=9xvXQrp33vNy%2BeavHmVbOZSTo4ObNn4nMkCrQaZW0Iw%3D");
  const [darkLogo, setDarkLogo] = React.useState("https://rfcapp.blob.core.windows.net/rfc-public/logo/png/icon_trimmed_transparent_white.png?sp=r&st=2022-11-02T03:35:17Z&se=2032-11-02T11:35:17Z&spr=https&sv=2021-06-08&sr=b&sig=zfZL80iwALjHXponyRO%2FNz3u3Oxh2FDkJCQ5Uv4x7U8%3D");

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const location = useLocation();

  const theme = useTheme();

  const navigate = useNavigate();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <AppBar position="fixed" sx={{ bgcolor: trigger ? "primary.contrastText" : "transparent" }} >
      <Container maxWidth="xl">

        <Toolbar>
          {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}

          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'primary',
              textDecoration: 'none',
            }}
          >

            <img style={{
              height: '50px',
              width: 'auto'
            }} src={theme.palette.mode === 'dark' ? darkLogo : lightLogo}
            />

          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="primary"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} href={page.route} onMouseDown={(e) => { e.preventDefault() }} onClick={(e) => { navigate(page.route) }}>
                  <Typography color={location.pathname === page.route ? "secondary" : "primary"} textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
              <MenuItem
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{ color: location.pathname.indexOf('case-study') > -1 ? "red" : "primary", fontWeight: "strong" }}
              >
                <Typography textAlign="center">Case Study</Typography>

              </MenuItem>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                  style: {
                    width: "500px",
                    height: "200px",
                    maxHeight: 'unset',
                    maxWidth: 'unset',
                  }
                }}
              >
                {solutions.map((s, index) => {
                  const { icon: Icon } = s
                  return (
                    <MenuItem key={index} onClick={() => { navigate(`case-study/${s.StudyID}`) }}>
                      <ListItemIcon>
                        <Icon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>{s.name}</ListItemText>
                    </MenuItem>
                  )
                })

                }
              </Menu>
            </Menu>
          </Box>
          {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'primary',
              textDecoration: 'none',
            }}
          >
            <img style={{
              height: '25px',
              width: 'auto'
            }} src={theme.palette.mode === 'dark' ? darkLogo : lightLogo}
            />
          </Typography>


          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

            {pages.map((page) => (
              <Button
                // color="red"
                // color={location.pathname === page.route ? "secondary" : "primary" }
                onMouseDown={(e) => { e.preventDefault() }}
                key={page.name}
                onClick={(e) => { e.preventDefault(); navigate(page.route) }}
                sx={{ color: location.pathname === page.route ? "red" : "primary", fontWeight: "strong" }}
              >
                {page.name}
              </Button>

            ))}

            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              sx={{ color: location.pathname.indexOf('case-study') > -1 ? "red" : "primary", fontWeight: "strong" }}
            >
              Case Study
            </Button>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              PaperProps={{
                style: {
                  width: "500px",
                  height: "200px",
                  maxHeight: 'unset',
                  maxWidth: 'unset',
                }
              }}
            >
              {solutions.map((s, index) => {
                const { icon: Icon } = s
                return (
                  <MenuItem key={index} onClick={() => { navigate(`case-study/${s.StudyID}`) }}>
                    <ListItemIcon>
                      <Icon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{s.name}</ListItemText>
                  </MenuItem>
                )
              })

              }
            </Menu>

          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={`Toggle ${theme.palette.mode === 'dark' ? 'light' : 'dark'} Mode`}>
              <IconButton sx={{ mr: 2 }} color="primary" onMouseDown={(e) => { e.preventDefault() }} onClick={(e) => { e.preventDefault(); toggleColorMode() }}>
                {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon color="primary" />}
              </IconButton>
            </Tooltip>
          </Box>


        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default Header;