import { customizeService } from "../../_services/customize.services";
import { filesService } from "../../_services/files.service";
import { generalService } from "../../_services/general.services";


export const fileProcessorLogos = async (blob, setUploading, origin, owner, setImage) => {

    const { type } = blob;
    // console.log(blob)
    const { companyID, _id } = owner
    // Convert to Base 64 as you'll need to the send the HTTP post as B64
    const base64Blob = await blobToBase64(blob);
    // Create the image componennt.
    const imageData = {
        ownerMongoID: _id,
        companyID,
        fileType: type.split('/')[1],
        base64Blob,
        blobContentType: type,
        origin: origin,
        ownerCollection: "users"
    }

    // console.log(imageData);
    await customizeService.UploadCorporateLogo(imageData)
        .then((response) => {
            setImage(response.data.downloadURL);
            setUploading(false);
        }).catch((error) => {
            console.log(error);
            setUploading(false);
        })

}

export const fileProcessor = async ({ ownerCollection, blob, setUploading, origin, owner, setUploadImageResult, serviceApp }) => {
    const { type } = blob;
    // console.log(blob)
    const { companyID, _id } = owner
    // Convert to Base 64 as you'll need to the send the HTTP post as B64
    const base64Blob = await blobToBase64(blob);
    // Create the image componennt.
    const imageData = {
        ownerMongoID: _id,
        companyID,
        fileType: type.split('/')[1],
        base64Blob,
        blobContentType: type,
        origin: origin,
        ownerCollection
    }

    // console.log("Uploading Image" , imageData);
    setUploading(true);

    try {
        const uploadFileResponse = await filesService.uploadFile(serviceApp, imageData);
        // console.log("Response in file upload", uploadFileResponse);
        if (setUploadImageResult) {
            // Set Image result should be used exclusively to save the entire data Object.
            setUploadImageResult(uploadFileResponse);
        }
        setUploading(false);

        return uploadFileResponse;

    } catch (err) {
        console.log(err);
        setUploading(false);
    }
}

export const getFilesById = async (id, _id) => {
    if (id) {
        const file = await filesService.getById(id);
        return file;
    } else {

    }
}


export const getBloBSasUri = async (data) => {
    // Get the file by id.
    const file = await getFilesById(data.id, data._id);
    // Get the file URL.
    const url = await generalService.getBlobSasUri(file);
    // console.log(url)

    return url;

}

export function blobToBase64(blob) {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend  =() => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}

export function binEncode(data) {
    var binArray = []
    var datEncode = "";

    for (let i = 0; i < data.length; i++) {
        binArray.push(data[i].charCodeAt(0).toString(2));
    }
    for (let j = 0; j < binArray.length; j++) {
        var pad = padding_left(binArray[j], '0', 8);
        datEncode += pad + ' ';
    }
    function padding_left(s, c, n) {
        if (!s || !c || s.length >= n) {
            return s;
        }
        var max = (n - s.length) / c.length;
        for (var i = 0; i < max; i++) {
            s = c + s;
        } return s;
    }
    
    return binArray;
}