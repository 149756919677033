
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    connectionString: 'InstrumentationKey=6ab75b9c-74ef-484b-b254-9947031ddfab;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/',
    extensions: [reactPlugin],
    // extensionConfig: {
    //   [reactPlugin.identifier]: { history: browserHistory }
    // },
    enableAutoRouteTracking: true
  }
});
appInsights.loadAppInsights();
// appInsights.addTelemetryInitializer((item) => {
//   const ipAddress = item.tags & item.tags["ai.location.ip"];
//   if(ipAddress){
//     item.baseData.properties = {
//       ...item.baseData.properties,
//       "client-ip": ipAddress
//     };
//   }
// })

export { reactPlugin, appInsights };