import React from 'react';
import Footer from "./footer/Footer";
import Header from './header/header';
import { Box, CssBaseline, styled, Toolbar } from '@mui/material';
import { Outlet } from "react-router-dom";


const OuterContainer = styled(Box)`
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  overflow: auto;
`;

const StyledMain = styled('main')`
  
`;


const Layout = () => {
    return (
            <OuterContainer>
                <CssBaseline />
                <Header />
                <Toolbar/>
                <StyledMain>
                    <Outlet/>
                </StyledMain>
                <Footer />
            </OuterContainer>
    )
}
export default Layout;
