import React, { Fragment, useEffect, useState } from "react";
// import VerificationCamera from "./Camera/Camera";
import { Root, Preview, Footer, GlobalStyle } from "./styles";
import './index.scss';
import { Card, CardContent, Grid, IconButton } from "@mui/material";
import { toastr } from "react-redux-toastr";
import { fileProcessor } from "../../_helpers/files/consume.files";
import { identityVerificationService } from "../../_services/identityverification.services";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';
import { Beforeunload } from 'react-beforeunload';

const IDVCamera = (props) => {

  const { sessionID } = props.match.params;
  const [session, setSession] = useState(null)
  const [error, setError] = useState(false);
  const [IDVStep, setIDVStep] = useState(1);
  const [cardImageDocument, setCardImageDocument] = useState(undefined);
  const [cardImageSelfie, setCardImageSelfie] = useState(undefined);
  const [uploading, setUploading] = useState(false);
  const [uploadImageResult, setUploadImageResult] = useState(null);
  const [processComplete, setProcessComplete] = useState(false);
  // const [loading, setLoading] = useState(false)

  // useBeforeunload((event) => {
  //     event.preventDefault();
  //     alert("leaving")

  // });
  const [images, setImages] = useState({
    documentImage: null,
    documentImageCompleteTime: null,
    selfieImage: null,
    selfieImageCompleteTime: null
  });

  useEffect(() => {
    // console.log("Check Images", images)
  }, [images])
  // Call for the Check Identity on Mount.
  useEffect(() => {
    if (session?.dropped !== true && session?.existingFaceID !== true) {
      setTimeout(() => {
        checkIdentitySession();
      }, [1500])

    }
  }, [session])
  // First, validate if the session ID has expired or not.
  const checkIdentitySession = React.useCallback(async () => {
    try {
      const responseSession = await identityVerificationService.validateIdentityVerification({ data: { sessionID }, params: { mobileAccess: session?.opened ? false : true } });
      // console.log("Response Session", responseSession.data)
      setSession(responseSession.data)
    } catch (err) {
      toastr.error("Error refeshing IDV, please try again.")
    }
  }, [session]);

  const uploadImage = React.useCallback(async (document) => {
    // Create Document Object to Upload
    const data = {
      origin: document ? 'identityVerifications/document' : 'identityVerifications/selfie',
      owner: session,
      blob: document ? cardImageDocument : cardImageSelfie,
      setUploading,
      setUploadImageResult,
      ownerCollection: "identityverification",
      serviceApp: true
    }

    // console.log("data", data)

    const uploadResponse = await fileProcessor(data);
    // console.log(uploadResponse)
    // Set Status to 200
    if (uploadResponse.status === 200) {

      images.documentImage = document === true ? uploadResponse.data.mongoID : images.documentImage;
      images.documentImageCompleteTime = document === true ? new Date() : images.documentImageCompleteTime;
      images.selfieImage = document === true ? images.selfieImage : uploadResponse.data.mongoID;
      images.selfieImageCompleteTime = document === true ? images.selfieImageCompleteTime : new Date();

      setImages((prevState) => ({
        ...prevState,
        ...images
      }))
    } {
      // @TODO Else, try again.

    }
  }, [cardImageDocument, cardImageSelfie])

  const complete = React.useCallback(async () => {

    const updateData = {
      params: {
        mongoID: session._id
      },
      data: images
    }


    if (IDVStep < 3) {
      // Data to be updated.
      // console.log("Update Data", updateData);
      // If Document Image & Selfie exist, persist changes.
      if (images.documentImage !== null && images.selfieImage !== null) {
        updateData.data.dateTimeCompleted = new Date();
        setProcessComplete(true);
      }

      // console.log("Update Data after validation Time Completed.", updateData);

      const updateResponse = await identityVerificationService.update(updateData);
      // Once we see that the data was updated, go to next step.
      if (updateResponse.status === 200) {
        setIDVStep((v) => v + 1);
      } else {
        toastr.error("Failed to update", "Your Session was not updated, please try again by clicking the upload button. If the Problem Persists, please restart the process.")
      }
    }
  }, [cardImageSelfie, cardImageDocument])

  const updateSession = React.useCallback(async () => {
    if(!session.existingFaceID){
      const idVParams = {
        mongoID: session._id
      }
      const idVData = {
        dropped: true,
        dateTimeCompleted: new Date()
      }
      // Update IDV that there is an existing FaceID Matched as True.
      await identityVerificationService.update({ params: idVParams, data: idVData });
    }
  }, [session])


  return (
    <>
      <Beforeunload onBeforeunload={updateSession} />
      <div className='content live-gradient'>
        <div className='row camera-area align-items-center justify-content-center'>
          {/**Form Start */}
          <Card
            style={{
              height: "90vh",
              minWidth: "90vw",
              boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              borderRadius: "8px",
            }}
            className="row justify-content-center align-items-center"
            variant="outlined"
          >
            <CardContent>

              {/* {/*IDVStep === 1 && session?.dateTimeCompleted === null && IDVStep !== 3 && !session.dropped && !session.existingFaceID && (
                // <VerificationCamera
                //   onCapture={blob => setCardImageSelfie(blob)}
                //   onClear={() => setCardImageSelfie(undefined)}
                //   image={cardImageSelfie}
                //   document={false}
                //   onUpload={(document) => uploadImage(document)}
                //   images={images?.selfieImage}
                //   onComplete={() => complete()}
                //   IDVStep={IDVStep}
                // />
              )} */}

              {/* {IDVStep === 2 && session?.dateTimeCompleted === null && IDVStep !== 3 && !session.dropped && !session.existingFaceID && (
                // <VerificationCamera
                //   onCapture={blob => setCardImageDocument(blob)}
                //   onClear={() => setCardImageDocument(undefined)}
                //   image={cardImageDocument}
                //   document={true}
                //   onUpload={(document) => uploadImage(document)}
                //   images={images?.documentImage}
                //   onComplete={complete}
                //   IDVStep={IDVStep}
                // />
              )} */}



              {(session?.dateTimeCompleted !== null || IDVStep === 3) && session && !session.dropped && !session.existingFaceID &&
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CheckCircleOutlineIcon color="success" sx={{ fontSize: 200 }} />
                  Complete, you may now close this tab.
                </Grid>
              }

              {session?.existingFaceID &&
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CheckCircleOutlineIcon color="success" sx={{ fontSize: 200 }} />
                  We've already located you.
                </Grid>
              }

              {session?.dropped && !session?.existingFaceID &&
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <ErrorIcon color="warning" sx={{ fontSize: 200 }} />
                  This session was dropped, you'll need to restart the process.
                </Grid>
              }


            </CardContent>
          </Card>
        </div>
      </div>
    </>


  );
}

export default IDVCamera