import React from "react"
import { 
    Checkbox, 
    Divider, 
    FormControl, 
    FormControlLabel, 
    FormHelperText, 
    Grid, 
    List, 
    ListItem, 
    ListItemIcon, 
    ListItemText, 
    Tooltip, 
    Typography 
} from "@mui/material"
import BadgeIcon from '@mui/icons-material/Badge';
import { useSelector } from "react-redux";
import HideSourceIcon from '@mui/icons-material/HideSource';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import PersonIcon from '@mui/icons-material/Person';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


const BlogProfile = () => {

    const publicuser      = useSelector(state => state.publicuser.user);
    const theme           = useTheme();

    return(
        <Grid
            container
            direction={{ xs: 'column', md: 'row', lg: 'row' }}
            alignItems="center"
            justifyContent="center"
            spacing={1}
            sx={{
                width: "100%"
            }}
        >
            <Grid item xs={10} md={5} lg={3} container>
                <Grid>
                    <List dense={false}>
                        <ListItem>
                            <Tooltip title="Full Name">
                                <ListItemIcon>
                                    <BadgeIcon />
                                </ListItemIcon>
                            </Tooltip>
            
                            <ListItemText
                                primary={`${publicuser.firstName} ${publicuser.lastName}`}
                            />
                        </ListItem>
                        <ListItem>
                            <Tooltip title="Posting Name">
                                <ListItemIcon>
                                    { publicuser.anonymous ? <TheaterComedyIcon /> : <PersonIcon/>} 
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText
                                primary={publicuser.anonymous ? publicuser.anonymousName : `${publicuser.firstName} ${publicuser.lastName}`}
                            />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
            <Divider  orientation={useMediaQuery(theme.breakpoints.down("md")) ? "horizontal" : "vertical"} flexItem>
                My Profile
            </Divider>
            <Grid item xs /*xs={10} md={5} lg={7} sx={{width: "300px"}}*/>

                <FormControl
                    required
                    // error={formik.touched.tos && Boolean(formik.errors.tos)}
                    component="fieldset"
                    variant="standard"
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                // checked={formik.values.tos}
                                // onChange={formik.handleChange}
                                name="anonymous"
                            />
                        }
                        label={
                            <Typography component="div" style={{ fontSize: "6px" }}>
                                <Typography component="span">Anonymous Name</Typography>
                            </Typography>
                        }
                    />
                    {/* <FormHelperText>{formik.touched.tos && formik.errors.tos}</FormHelperText> */}
                </FormControl>
            </Grid>
        </Grid>

    )

}


export default BlogProfile