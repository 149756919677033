import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { ListItem } from '@mui/material';
import { Link } from 'react-router-dom';
const ContactCard = (props) => {

    const { contactTitle, department, email, phone } = props;

    return (
        <>
            <Card sx={{ width: 300 }}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {contactTitle}
                    </Typography>
                    <Typography variant="h5" component="div">
                        {department}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        <AlternateEmailIcon /> | <PhoneAndroidIcon />
                    </Typography>
                    <Typography sx={{justifyContent: "center"}} variant="subtitle1">
                        <Link href={email} target="_top">{email}</Link>
                        <br/>
                        {phone}
                    </Typography>
                </CardContent>
            </Card>


        </>
    )
}
export default ContactCard;