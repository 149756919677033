import { Typography } from '@mui/material';
import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';


const SectionTitle = ({subtitle, title, description, textAlign, radiusRounded}) => {
    return (
        <div className={`section-title ${textAlign}`}>
            <AnimationOnScroll 
                animateIn="animate__fadeIn"
                animatePreScroll={true}
                offset={10}
            >
                {/* <Typography className={`subtitle ${radiusRounded}`}></Typography> */}
                <Typography variant="h5" color="secondary" component="span" dangerouslySetInnerHTML={{__html: subtitle}}></Typography>
            </AnimationOnScroll>
            
            <AnimationOnScroll 
                animateIn="animate__fadeIn"
                animatePreScroll={true}
                offset={10}
            >
                <Typography variant="h4" color="secondary.contrastText" dangerouslySetInnerHTML={{__html: title}}></Typography>
            </AnimationOnScroll>

            <AnimationOnScroll 
                animateIn="animate__fadeIn"
                animatePreScroll={true}
                offset={10}
            >
                <Typography variant="h4" color="secondary.contrastText" dangerouslySetInnerHTML={{__html: description}}></Typography>
            </AnimationOnScroll>
        </div>
    )
}
export default SectionTitle;