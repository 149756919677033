import { authHeader, serviceAppAuth } from '../_helpers';
import { handleResponse } from './services.index';

 

import { config } from '../_helpers/apiConfig';



export const filesService = {
    getById,
    getByIdMongo,
    uploadFile,
    findOne
};


async function findOne(serviceApp, filter) {
    let query = filter ? filter : {}
    // console.log("Sending Filter Data", query);
    const requestOptions = {
        method: 'POST',
        headers: serviceApp ?  await serviceAppAuth() : authHeader(),
        body: JSON.stringify(query)
    };
    return fetch(`${config.apiUrl}/database/files/findone`, requestOptions).then(handleResponse);
}

async function getById(id) {
    
    const requestOptions = {
        method: 'GET',
        headers: await serviceAppAuth()
    };

    return fetch(`${config.apiUrl}/database/files/find/${id}`, requestOptions).then(handleResponse);
}

async function uploadFile(serviceApp, data) {
    const requestOptions = {
        method: 'POST',
        headers: serviceApp ?  await serviceAppAuth() : authHeader(),
        body: JSON.stringify(data)
    };
    return await fetch(`${config.apiUrl}/database/files/upload`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function getByIdMongo(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/database/files/findbyid/${id}`, requestOptions).then(handleResponse);
}
