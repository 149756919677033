import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import CSVHandler from "../../_components/CSVHandler/main";

const TestComponent = (props) => {


    return(
        <Box sx={{flexGrow: 1}}>
            <Grid container spacing={2} minHeight={"100vh"}>
                <Grid 
                    xs 
                    display="flex" 
                    justifyContent="center" 
                    alignItems="center"
                >
                    <CSVHandler {...props} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default TestComponent